import env from 'react-dotenv';

export const HandleApi = async({"apiUrl":apiUrl, "method":method, "bodyData":bodyData , "authAccess":authAccess }) => {

    console.log("handleapi data : ",{"apiUrl":apiUrl, "method":method, "bodyData":bodyData , "authAccess":authAccess })
    
   const fetchData =  await fetch(apiUrl, {
    method:method,
    headers:{
        "Content-Type":"application/json",
        "Accept":"application/json",
        "Access-Control-Allow-Origin":"*",
        "Authorization":`Bearer ${authAccess}`
    },
    body:JSON.stringify( bodyData )
   });

   console.log("fetchApi json : ", fetchData.status,fetchData.status == '500' );
   
   if(fetchData.status === 200){

    const json = await fetchData.json();

    //console.log("login json : ",json.Status, fetchData.status==500);

       return  json ;
   }
   
       else if(fetchData.status === 500 || fetchData.status === 501 || fetchData.status === 502 || fetchData.status === 503 || fetchData.status === 400 || fetchData.status === 402 || fetchData.status === 404 || fetchData.status === 302 ){
        console.log("entered 500");
        window.alert("Technical Issue, We are fixing it - ",fetchData.status)
            // setTimeout(() => {
            //     window.location.reload();
            // },500) 
       }

       else if(fetchData.status === 401 ){
        console.log("entered 401");
        window.alert("Unauthenticated.")
        window.location.replace(`${env.REACT_APP_UAPI_URL}`);
       }

        else{
        setTimeout(() => {
            window.alert("Technical issue, Please Try Again - ", fetchData.status)
           // window.location.reload();
        },1000)
    }

}