import React, { useRef } from 'react';
import Header from '../components/Header'
import { Box, Button, FormControl, InputLabel, MenuItem, Paper, Typography, Select, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Pagination, TextField, InputAdornment, Divider, LinearProgress, Tooltip, Dialog, DialogTitle, DialogContent, Snackbar, Alert, CircularProgress , Menu, Drawer } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import CloseIcon from '@mui/icons-material/Close';
import env from 'react-dotenv';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Switch, { switchClasses } from '@mui/joy/Switch';
import { Theme } from '@mui/joy';
import moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import Checkbox from '@mui/material/Checkbox';
import imageLogo from "../images/otpImage.png";
import eezibLogo from "../images/eezib.png";
import { HandleApi } from '../HandleApi';
import Footer from './Footer';
import Slider from '@mui/material/Slider';
import { useTheme, useMediaQuery } from '@mui/material';
import DownloadingIcon from '@mui/icons-material/Downloading';
import downloadfile from "../images/downloadExcel.jpg";
import exceLogo from "../images/excelPng.png";
import pdfLogo from "../images/pdfIcon.png";
import MenuIcon from '@mui/icons-material/Menu';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as XLSX from "xlsx";
import jsPDF from 'jspdf';
import ecomImg from "../images/ecommerce.jpg";
import posImg from "../images/POS.jpg";
import atmImg from "../images/ATM.jpg";
import contactLess from "../images/contactless.jpg";
// import "jspdf-autotable";



const ActivatedCard = () => {
    
    const [age, setAge] = React.useState('');
    const [age2, setAge2] = React.useState('');
    const [age3, setAge3] = React.useState('');
    const [category , setCategory] = React.useState();  
    const [progress1, setProgress1] = React.useState(false);
    const [progress3, setProgress3] = React.useState(false);
    const [categoryId , setCategoryId] = React.useState('');
    const [subCat , setSubCat] = React.useState('')
    const [handleSubCategory , setHandleSubCategory] = React.useState('');
    const [tableData , setTableData] = React.useState([]);
    const [dialogCardDetail , setDialogCardDetail] = React.useState(false);
    const [lockStatus, setLockStatus] = React.useState('');
    const [cardLockReason , setCardLockReason] = React.useState('');
    const [entityId , setEntityId] = React.useState('');
    const [snackMessage , setSnackMessage ] = React.useState('');
    const [snackRightOpen, setSnackRightOpen ] = React.useState(false);
    const [ dialogProgress, setDialogProgress ]  = React.useState(false)
    const [ cardLockProgress, setCardLockProgress ] = React.useState(false);
    const [fetchUserBalance , setFetchUserBalance] = React.useState("");
    const [snackEError , setSnackEError] = React.useState('');
    const [ snackRightEOpen, setSnackRightEOpen ] = React.useState(false);
    const [page , setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [openLimit , setOpenLimit ] = React.useState(false);
    const [ inputLimitValue ,setInputLimitValue ] = React.useState('')
    const [ inputLimitValueID , setInputLimitValueID ] = React.useState('');
    const [ enableTrans, setEnableTrans ] = React.useState(false);
    const [ enabletransID   ,  setEnableTransID ] = React.useState('');
    const [ enabletransValue , setEnableTransValue ] = React.useState("");
    const [lockApi , setLockApi] = React.useState(''); 
    const [limitProgress , setLimitProgress] = React.useState(false);
    const [ transProgress , setTransProgress ] = React.useState(false);
    const  [transTableData , setTransTableData ] = React.useState();
    const [openTransTable , setOpenTransTable] = React.useState(false);
    const [ switchValue , setSwithValue ] =React.useState(false);
    const [ transPage , setTransPage ] = React.useState(1);
    const [ transRowPerPage , setTransRowPerPage ] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [ catClick , setCatClick ] = React.useState("");
    const [checked, setChecked] = React.useState(0);
    const [ finalTable , setFinalTable ] = React.useState(false);
    const [ openConcent , setOpenConcent] = React.useState(false);
    const [ concentData , setConcentData ] = React.useState();
    const  [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [check, setCheck] = React.useState(false);
    const [ progress , setProgress ] = React.useState(false);
  const [ currentData , setCurrentData ] = React.useState();
  const [ verifyDataDialog , setVerifyDataDialog ] = React.useState(false);
  const [otpValue , setOtpValue] = React.useState('');
  const  [openKYC , setOPenKYC ] = React.useState(false)
  const [ kycId , setKycId ] = React.useState('');
  const [verifyUser , setVerifyUser] = React.useState("");
  const [ verifyEmail , setVerifyEmail ] = React.useState('');
  const [ verifyDob , setverifyDob ] = React.useState('');
  const [ verifyAddress , setVerifyAddress ] = React.useState('');
  const [ verifyAddress2 , setVerifyAddress2 ] = React.useState("");
  const [ verifyAddress3 , setVerifyAddress3 ] = React.useState("");
  const [ city , setCity ] = React.useState("");
  const [ state , setState ] = React.useState('');
  const [ pincode, setPinCode ] = React.useState("");
  const [ downloadTnx , setDownloadTnx ] = React.useState(false); 
  const [ startDate , setStartDate ] = React.useState("");
  const [ endDate , setEndDate ] = React.useState("");
  const [ reportData , setReportData ] = React.useState();
  const [ cardTransId , setCardTransId ] = React.useState("");


  const [ txnProg , setTxnProg ] = React.useState(false); 

  const  [ aadharNo , setAadharNo ] = React.useState('');
  const [ panNo , setPanNo ] = React.useState('');
  const [ fatherName , setFatherName ] = React.useState('');
  const  [ imgfile , setImgFile ] = React.useState('');
  const   [ kycAddress , setKycAddress ] = React.useState('');

  const [ otpEntityId , setOtpEntityId ] = React.useState('');

  const [ otpVerify , setotpVerify ] = React.useState(false);

  const [selectedFile, setSelectedFile] = React.useState(null);

  const [base64Image, setBase64Image] = React.useState('');

  const [imgfileType , setImgFileTYpe] = React.useState('');
  
  const  [ limitApi , setLimitApi] = React.useState('');

  const [decideLimit , setDecideLimit ] = React.useState(''); 

  const [ finoLimit ,setFinoLimit ] = React.useState("");

  const [ verifyProg , setVerifyProg ] = React.useState(false);

  const [ otpProg , setOtpProg ] = React.useState(false);

  const [ convertPhy , setConvertPhy ] = React.useState(false);

  const [ anchorEmenu, setAnchorEMenu ] = React.useState(null);
  const [ currentMenuData , setCurrentmenuData ] = React.useState();
  const [ currentMenuId , setCurrentmenuId ] = React.useState("");

  const [ cardLimitData , setCardLimitData ] = React.useState();
  const [ ecommLimit , setEcomLimit ] = React.useState();
  const [ Pos , setPos ] = React.useState();
  const [Atm , setAtm ] = React.useState(); 
  const [ contactless , setContactless ] = React.useState();
  const [ limitCardProgress , setLimitCardProgress ] = React.useState(false);
  const [ cardsLimitId , setCardsLimitId ] = React.useState("");
  const [ EcomCheck , setEomCheck] = React.useState(0);
  const [ PosCheck , setPosCheck ] = React.useState(0)
  const [ atmCheck , setAtmCheck ] = React.useState(0);
  const [ contactlessCheck , setContactlessCheck ] = React.useState(0);
  const [enableTxnData , setEnableTxnData ] = React.useState(); 



  const handleDTxnClose = () => {
    setDownloadTnx(false)
  }

  const handleEcomCheck = (e) => {
    const bool = EcomCheck === 0 ? 1 : 0;
    setEomCheck(bool);
}

const handlePosCheck = (e) => {
  const bool = PosCheck === 0 ? 1 : 0;
  setPosCheck(bool);
}

const handleAtmCheck = (e) => {
  const bool = atmCheck === 0 ? 1 : 0;
  setAtmCheck(bool);
}

const handleContLessCheck = (e) => {
  const bool = contactlessCheck === 0 ? 1 : 0;
  setContactlessCheck(bool);
}

  console.log("ecom limit : ",ecommLimit, "seperate value : ",cardLimitData?.Ecommerce?.limit);
  console.log("card limit data : ",cardLimitData);

  const [value, setValue] = React.useState(cardLimitData?.Ecommerce?.limit || 101 );

  React.useEffect(() => {
    setValue(cardLimitData?.Ecommerce?.limit || 101);
  }, [cardLimitData]);

 
  const handleSliderChange = (event, newValue) => {
    console.log("slider value : ",newValue);
    setEcomLimit(newValue);

  };

  const handleSliderChangePos = (event, newValue) => {
    console.log("slider value : ",newValue);
    setPos(newValue);

  };

  const handleSliderChangeAtm = (event, newValue) => {
    console.log("slider value : ",newValue);
    setAtm(newValue);

  };

  const handleSliderChangeContact = (event, newValue) => {
    console.log("slider value : ",newValue);
    setContactless(newValue);

  };

  // const handleTextFieldChange = (event) => {
  //   const newValue = event.target.value;

  //   if (newValue === '' || !isNaN(Number(newValue))) {
  //     setValue(newValue === '' ? '' : Number(newValue));
  //     setCardLimitData(prevState => ({
  //       ...prevState,
  //       Ecommerce: {
  //         ...prevState.Ecommerce,
  //         limit: newValue === '' ? '' : Number(newValue)
  //       }
  //     }));
  //   }
  // };

  //   const handleTextFieldChange = (event) => {
  //   const newValue = event.target.value;

  //   if (newValue === '' || !isNaN(Number(newValue))) {
  //     setValue(newValue === '' ? '' : Number(newValue));
  //     setCardLimitData(prevState => ({
  //       ...prevState,
  //       Ecommerce: {
  //         ...prevState.Ecommerce,
  //         limit: newValue === '' ? '' : Number(newValue)
  //       }
  //     }));
  //   }
  // };


  const [anchorElVoucher, setAnchorElVoucher] = React.useState(null);

    const openVoucher = Boolean(anchorElVoucher);

    const handleVoucherClick = (event) => {
      setAnchorElVoucher(event.currentTarget);
    };
    const handleVoucherClose = () => {
      setAnchorEl(null);
    };


  const openMenu = Boolean(anchorEmenu);

  const handleMenuClick = ( event ,actions, id) => {
    console.log("event data : ",event);
    setAnchorEMenu(event.currentTarget);
    console.log("actions data : ",actions);
    console.log("current id : ",id);
    setCurrentmenuData(actions);
    setCurrentmenuId(id)
  }

  const handleMenuplation = (data) => {
     handleMenuClick(data);
    console.log("menu data : ",data)
  }

  const handleMenuClose = () => {
    setAnchorEMenu(null);
  }



  const [ add1 , setAdd1 ] = React.useState('');
  const  [ add2 , setAdd2 ] = React.useState('');
  const [ add3 , setAdd3 ] = React.useState('');
  const [ phyCity , setPhyCity ] = React.useState('');
  const [ phyState , setPhyState ] = React.useState('');
  const [ phyPin , setPhyPin ] = React.useState('');
  const [ phyApi , setPhyApi ] = React.useState('');
  const [ phyId , setPhyId ] = React.useState('');
  const [ phyProgress , setPhyProgress ] = React.useState(false);



  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));



  const fileInputRef = useRef(null);

  const authAccess = Cookies.get("demoCAuth");

  const handleVerification = async() => {

    setOpenBackdrop(true);

    const url = process.env.REACT_APP_UAPI_URL;
    const apiUrl = `${url}/api/storeform`;
    const method = "POST";
    const bodyData = { "id":kycId, "aadhar":aadharNo, "pan":panNo, "fname":fatherName, "per_add":kycAddress  }

    // const fetchData = await fetch(`${url}/api/storeform`, {
    //   method:"POST",
    //   headers:{
    //     "Content-Type":"application/json",
    //     "Authorization":`Bearer ${authAccess}`
    //   },
    //   body:JSON.stringify({ "id":kycId, "aadhar":aadharNo, "pan":panNo, "fname":fatherName, "per_add":kycAddress, "receipt":base64Image, "file_type":`${imgfileType}` })
    // });


    const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData, "authAccess":authAccess });


    // console.log({ "id":kycId, "aadhar":aadharNo, "pan":panNo, "fname":fatherName, "per_add":kycAddress, "receipt":base64Image, "file_type":`${imgfileType}` });
    // const json = await fetchData.json();
    console.log("json data : ",json);
    setOpenBackdrop(false);

    if(json){
      if(json.Status === "Success"){
        window.open(json.Message,"");

      setAadharNo("");
      setPanNo("");
      setFatherName("");
      setKycAddress("");
      setBase64Image("");
      setImgFileTYpe("");

      fileInputRef.current.value="";
        setOPenKYC(false);
      
      }

      if(json.Status === "Error"){
        setSnackRightEOpen(true);
        setSnackEError(json.Message);

        setTimeout(() => {
          setAadharNo("");
      setPanNo("");
      setFatherName("");
      setKycAddress("");
      setBase64Image("");
      setImgFileTYpe("");

      // fileInputRef.current.value="";
        setOPenKYC(false);
        },600)
      }
    }

  }

  const handleImageChange = (event) => {

    setBase64Image('');

    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setSelectedFile(file);

      if (file.size > 600 * 1024) { // 200 KB in bytes
        setSnackRightEOpen(true);
        setSnackEError('Image Should Not Be Greater Than 600 KB.');
        fileInputRef.current.value="";
        return;
      }

      const fileType = file.name.split(".")[1];
      setImgFileTYpe(fileType);
      const reader = new FileReader();
      reader.onloadend = () => {
        // reader.result contains the base64-encoded image data
        const baseData = reader.result.split(",")[1]
        setBase64Image(baseData);
      };
      reader.readAsDataURL(file)

    } else {
      setSelectedFile(null);
      window.alert('Please select a valid image file.');
      return
    }
  };


  const otpKycClose = () => {
    setotpVerify(false);
  }

  const fetchKycStatus = async(e, id) => {

    try{

    console.log(e, id)
    // const fetchData = await fetch(e, {
    //   method:"POST",
    //   headers:{
    //     "Content-Type":"application/json",
    //     "Accept":"application/json",
    //     "Authorization":`Bearer ${authAccess}`
    //   },
    //   body:JSON.stringify({ "id":id })
    // });

    const url = process.env.REACT_APP_UAPI_URL;
    const method = "POST";
    // const bodyData = { "id":kycId, "aadhar":aadharNo, "pan":panNo, "fname":fatherName, "per_add":kycAddress, "receipt":base64Image, "file_type":`${imgfileType}` }


    const json = await HandleApi({"apiUrl":e, "method":method, "bodyData":{"id":id}, "authAccess":authAccess });

    console.log("json data : ",json);

    setOpenBackdrop(false);

    if(json){

      if(json.Status === "Success" ){
        setSnackRightOpen(true);
        setSnackMessage(json.Message);
        
        if(json?.url){
          setTimeout(() => {
            window.open(json.url,'');
          },1000)
        }

      }

      if(json.Status === "Error"){
        setSnackRightEOpen(true)
          setSnackEError(json.Message)
      }

    }
    }catch(err){
        console.log(err)
        setSnackRightEOpen(true)
        setSnackEError(err);
    }

    

  }

  const kycFormData = async(e) => {

    try{

      setOpenBackdrop(true);

    // const fetchData = await fetch(`${url}/api/kycform`,{
    //   method:"POST",
    //   headers:{
    //     "Content-Type":"application/json",
    //     "Authorization":`Bearer ${authAccess}`
    //   },
    //   body:JSON.stringify({ "id":e })
    // });
    // const json = await fetchData.json();

    console.log("kyc id : ",e)

    const url = process.env.REACT_APP_UAPI_URL
    const apiUrl = `${url}/api/kycform`;
    const method = "POST";


    const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":{"id":e}, "authAccess":authAccess });

    console.log("kyc data json : ",json);
    setOpenBackdrop(false);


    if(json){
      if(json.Status === "Success"){

        if(json.Message.show_form === 1){
            setKycId(e);
            setAadharNo(json.Message.data.Aadhaar);
            setPanNo(json.Message.data.PAN);
            setFatherName(json.Message.data.FATHER_NAME);
            setKycAddress(json.Message.data.address);
            setOPenKYC(true);
        }

        if(json.Message.show_form === 0 ){
          const apiUrl = `${process.env.REACT_APP_UAPI_URL}${json.Message.url}`;
          console.log("form url data : ",apiUrl);
          const cardId = json.Message?.data.id;
          console.log('card id : ',cardId);

          fetchKycStatus(apiUrl, cardId);

        }

        

        }

      if(json.Status === "Error"){
        setSnackRightEOpen(true);
      setSnackEError(json.Message);
      }
    }

    }catch(err){
      console.log(err);
      setSnackRightEOpen(true);
      setSnackEError(err);
    }

    
  }

  console.log("aadhar : ",aadharNo , "  pan no : ",panNo)

  const handleOtpDialog = () => {
    setOpenConcent(false);
    setVerifyDataDialog(true);
    setCheck(false);
  }

  const verifyOtpClose = () => {

  }

    const handleCheck = (e) => {
      console.log("e : ",e );
      setCheck(e.target.checked);
    }

    const verifyDataClose = () => {
      setVerifyDataDialog(false);
    }

    console.log("check : ",check);

  console.log("concent data : ",concentData);

  const handleBackdropClose = () => {
    setOpenBackdrop(false)
  }
  
    const handleCheckedSwitch = (e) => {
        const bool = checked === 0 ? 1 : 0;
        setChecked(bool);
        Cookies.remove("tcValue");
        Cookies.set("tcValue","eeib");
    }

    const handleConcentClose = () => {
      setOpenConcent(false);
      setCheck(false);
    }

      const openCatMenu = Boolean(anchorEl);

      const handleCloseCatMenu = (event) => {
        setAnchorEl(null);
      };

      const handleClose = () => {
        setAnchorEl(null);
      };

    const navigate = useNavigate();
    const name = Cookies.get("userName");

    //console.log("switch value : ",switchValue);

   
    // const handleUserTable = () => {
    //   if(catClick === "Eezib Transcorp"){
    //     Cookies.remove("tcValue");
    //     Cookies.set("tcValue","transcorp")
    //       return tableData?.filter((data) => 
    //         data.category_name === "Eezib Fino Payments Bank"
    //       )
    //   }else if( catClick === "Eezib Fino Payments Bank" ){
    //     Cookies.remove("tcValue");
    //     Cookies.set("tcValue","fino")
    //     return tableData?.filter((data) => 
    //     data.category_name === ""
    //   )      
    // }
    //   else{
    //     Cookies.set("tcValue","eezib")
    //     return tableData
    //   }
    // }

    console.log("is view extra small : >>>>>>>>>>>>> ",isXs);


    const handleUserTable = () => {
      if(catClick === "Eezib Transcorp"){
        Cookies.remove("tcValue");
        Cookies.set("tcValue","transcorp")
          return tableData?.filter((data) => 
            data.cat_id === 4
          )
      }else if( catClick === "Eezib Fino Payments Bank" ){
        Cookies.remove("tcValue");
        Cookies.set("tcValue","fino")
        return tableData?.filter((data) => 
        data.cat_id === 3
      )      
    }
      else{
        Cookies.set("tcValue","eezib")
        return tableData
      }
    }
 
    const result = handleUserTable();
    console.log("result : ",result);



    const startIndex = (page -1)* rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        const slicedData = handleUserTable()?.slice(startIndex, endIndex); 

        const transStartIndex = (transPage - 1)* transRowPerPage;
        const transEndIndex = transStartIndex + transRowPerPage;
        const transSlicedData = transTableData?.slice(transStartIndex , transEndIndex);

        const handleTransTableClose = () => {
          setOpenTransTable(false);
        }

        const handleTransClose = () => {
          setEnableTrans(false);
        }

        const handleEnableTransChange = (e) => {
          setEnableTransValue(e.target.value)
        }

    const handleChange = (event) => {
        setAge(event.target.value);
      };

      const handleLimitChange = (e) => {
        setFinoLimit(e.target.value)
      }

      const handleLimitClose = () => {
        setOpenLimit(false)
      }
  
      const handleChange2 = (event) => {
        setSubCat(event.target.value);
        };

        const handleLockClose = () => {
          setDialogCardDetail(false);
        }

        const handleLockChange = (event) => {
          setLockStatus(event.target.value)
        }

        const handleRightSnackClose = () => {
            setSnackRightOpen(false)
        }

        const handleRightSnackEClose = () => {
          setSnackRightEOpen(false);
        }

        console.log(authAccess);


  const getCategory = async() => {

    const url = process.env.REACT_APP_UAPI_URL

    try{

      setProgress1(true)
    
        const data = await fetch(`${url}/api/category`,{
          headers:{
            "Accept":"application/json",
            "Authorization":`Bearer ${authAccess}`,
          },
        });
        const json = await data.json();
    
        if(json){
          if(json.Status === "Success"){
            setCategory(json.Message);
            setProgress1(false)
          } else if( json.message === "Unauthenticated." ){
            setSnackRightEOpen(true);
            setSnackEError("Session Expire, Login Again..")
            setTimeout(() => {
              Cookies.remove("demoCAuth")
              navigate("/");
          },3000)
          }
        }

    }catch(err){
      console.log(err);
      setSnackRightEOpen(true);
      setSnackEError(err)
    }
  }
  // const subCategory = async() => {
  //   setProgress2(true)


  //   const data = await fetch(`http://siddhi.local/b2c_live/api/subcategory/${age2}`, {
  //     headers:{
  //       "Accept":"application/json",
  //       "Authorization":`Bearer ${authAccess}`,
  //     },
  //   });
  //   const json = await data.json();
  //   if(json){
  //     if(json.Status === "Success"){
  //       setSubCategoryCard(json.Data)
  //       setProgress2(false)
  //     }
  //   }
  // }

  const handleLockUnlock = (e) => {
    setEntityId(e)
    setDialogCardDetail(true)
  }
  
  // React.useEffect(() => {
  //   getCategory();
  // },[])



  const handleButtonClick = async() => {
    const url = process.env.REACT_APP_UAPI_URL
    try{      
      setDialogProgress(true)
      const fetchData = await fetch(`${url}/api/activatedCards`, {
        method:"POST",
        headers:{
          "Content-Type":"application/json",
          "Authorization":`Bearer ${authAccess}`
        },
        body:JSON.stringify({"cat_id":age2})
      });
      const json = await fetchData.json();
      console.log("activated cards json : ",json)
      
      if(json.Status === "Success"){
        if(json.Message){
          const jsonTableData = json.Message;
          setTableData(jsonTableData);
          setDialogProgress(false);
        }  
      }
      if(json.Status === "Error"){
        if(json.Message){
          setSnackRightEOpen(true);
          setSnackEError(json.Message)
        }
      }
      setDialogProgress(false)
    }catch(err){
      console.log(err);
      setDialogProgress(false);
    }

  }   


  async function handleCardLockUnlock(f,api){

    setCardLockProgress(true)
    setOpenBackdrop(true);

    try{
      // const dataFetch = await fetch( `${process.process.env.REACT_APP_UAPI_URL}${api}` , {
      //   method:"POST",
      //   headers:{
      //     "Content-Type":"application/json",
      //     "Authorization":`Bearer ${authAccess}`
      //   },
      //   body:JSON.stringify({ "id":f , "flag":lockStatus })
      // })
      // const json = await dataFetch.json();

      const apiUrl = `${process.env.REACT_APP_UAPI_URL}${api}`;
      const method = "POST";
      const bodyData = { "id":f }

      console.log("api url : ",apiUrl);

      const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData , "authAccess":authAccess });

      console.log("lock json : ",json);

      setCardLockProgress(false)
      setOpenBackdrop(false);
      if(json){
        if(json.Status === 'Success'){
            setCardLockProgress(false)
            setSnackRightOpen(true)
            setSnackMessage(json.Message);
            cardData();
        }
        else if(json.Status === "Error"){ 
          setCardLockProgress(false)
          setSnackRightEOpen(true);
          setSnackEError(json.Message)
        }
      }
    }catch(err){
      console.log(err);
      setSnackRightEOpen(true);
      setSnackEError(err)
    }


  }

  const fetchBalance = async(e, api) => {
    setOpenBackdrop(true);
    try{

      // const fetchApi = await fetch(`${api}`,{
      //   method:"POST",
      //   headers:{
      //     "Content-Type":"application/json",
      //     "Authorization":`Bearer ${authAccess}`
      //   },
      //   body:JSON.stringify({"id":e})
      // })
      // const json = await fetchApi.json();
      //   console.log("balance data : ",json);

      const method = "POST";
      const bodyData = {"id":e}

      const json = await HandleApi({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":authAccess });

      console.log("balance : ",json);

        setOpenBackdrop(false);
      if(json){
        if(json.Status === "Success"){
          if(json.Message){
            setSnackRightOpen(true)
            setSnackMessage(json.Message);
          }
        }
        else if(json.Status === "Error"){
          setSnackEError("")
          setSnackRightEOpen(true);
          setSnackEError(json.Message)
        }
      }
      
    }catch(err){
      setSnackRightEOpen(true);
      setSnackEError(err);
    }

  }

  const getCardDetails = async(e, api, redirect) => {
    setOpenBackdrop(true);

try{
  
  console.log("api : ", `"${api}` )

  const url = process.env.REACT_APP_UAPI_URL;
  const method = "POST";
  const bodyData = {"id":e}


const json = await HandleApi({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":authAccess });

  // const fetchData = await fetch( `${api}` , {
  //   method:"POST",
  //   headers:{
  //     "Content-Type":"application/json",
  //     "Authorization":`Bearer ${authAccess}`
  //   },
  //   body:JSON.stringify({ "id":e })
  // })
  // console.log({ "id":e })
  // const json =  await fetchData.json();
  console.log("card details : ",json);
   setOpenBackdrop(false);

  if(json){
    if(json.Status === "Success" ){
      window.open(json.Message, '');
    }
    else if(json.Status === "Error"){
      setSnackRightEOpen(true);
      setSnackEError(json.Message)
    }}
}catch(err){
  setSnackRightEOpen(true);
  setSnackEError(err);
}
    
    }

      
    const fetchTransitions = async( e , api, redirect ) => {
      setOpenBackdrop(true);
      console.log("fetch transition : ",api)
      try{

        const url = process.env.REACT_APP_UAPI_URL;
        const method = "POST";
        const bodyData = {"id":e}

       // const fetchData = await fetch( `${api}` ,{
        // const fetchData = await fetch( `${api}` ,{
        //     method:"POST",
        //     headers:{
        //       "Content-Type":"application/json",
        //       "Authorization":`Bearer ${authAccess}`
        //     },
        //     body:JSON.stringify({ "id":e })
        // });
        // const json  = await fetchData.json();

      const json = await HandleApi({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":authAccess });


        console.log("fetch transaction : ",json);
        setOpenBackdrop(false);
        if(json){
          if(json.Status === "Error"){
            setSnackRightEOpen(true);
            setSnackEError(json.Message);
          }
          if(json.Status === "Success"){
            if(redirect === "NO"){
              setTransTableData(json.Message);
              setOpenTransTable(true);
             // navigate("/viewCard", {state : {data : json.Message}})
            }
            else if(redirect === "Yes"){
              window.open(json.Message)
            }
          } 
        }
      }catch(err){
        setSnackRightEOpen(true);
        setSnackEError(err);
      }

      }

      const setcardLimit = async() => {
        setLimitProgress(true);
        const url = process.env.REACT_APP_UAPI_URL
          try{
            // const fetchData = await fetch( limitApi ,{
            //   method:"POST",
            //   headers:{
            //     "Content-Type":"application/json",
            //     "Authorization":`Bearer ${authAccess}` 
            //   },
            //   body:JSON.stringify( decideLimit === "transcorp" ?  { "id":inputLimitValueID, "limit":inputLimitValue } : decideLimit === "fino" ? { "id":inputLimitValueID, "limit":inputLimitValue, "period":finoLimit } : null )
            // });
            
            // const json = await fetchData.json();
            // console.log("card limit json : ",json)

            const url = process.env.REACT_APP_UAPI_URL;
            const method = "POST";
            const bodyData = decideLimit === "transcorp" ?  { "id":inputLimitValueID, "limit":inputLimitValue } : decideLimit === "fino" ? { "id":inputLimitValueID, "limit":inputLimitValue, "period":finoLimit } : null;

            const json = await HandleApi({"apiUrl":limitApi, "method":method, "bodyData":bodyData , "authAccess":authAccess });


            setLimitProgress(false)
    
            if(json){
    
              if(json.Status === "Success"){
                setSnackRightOpen(true)
                setSnackMessage(json.Message);

                setTimeout(() => {
                  setInputLimitValue("")
                },1000)
              }
    
              if(json.Status === "Error")
              setSnackRightEOpen(true);
                setSnackEError(json.Message);

                setTimeout(() => {
                  setInputLimitValue("")
                },1000)
            }

          }catch(err){
            setSnackRightEOpen(true);
            setSnackEError(err);
          }

      }

      const enableTxn = async(f, api) => {
        console.log("enable txn id : ",f)
        setTransProgress(true);
        try{
          // const fetchData = await fetch(`${url}${api}`, {
            //   method:"POST",
            //   headers:{
              //     "Content-Type":"application/json",
          //     "Authorization":`Bearer ${authAccess}`
          //   },
          //   body:JSON.stringify({ "id":f })
          // })
          // const json = await fetchData.json();

          const url = process.env.REACT_APP_UAPI_URL;
            const method = "POST";
            const bodyData = { "id":f }

            const json = await HandleApi({"apiUrl":`${url}${api}`, "method":method, "bodyData":bodyData , "authAccess":authAccess });


          setTransProgress(false)
          if(json){
            if(json.Status === "Success"){
              setSnackRightOpen(true)
              setSnackMessage(json.Message);
              cardData();
            }
            if(json.Status === "Error"){
              setSnackRightEOpen(true);
              setSnackEError(json.Message)
            }
          }
        }catch(err){
          setSnackRightEOpen(true);
            setSnackEError(err);
        }


      }


      const setCardPin = async(e, api) => {

        try{

          setOpenBackdrop(true);
          console.log("url : ",api)
          // const fetchData = await fetch(`${api}`,{
          //   method:"POST",
          //   headers:{
          //     "Content-Type":"application/json",
          //     "Authorization":`Bearer ${authAccess}`
          //   },
          //   body:JSON.stringify({ "id":e })
          // } );
  
          // const json = await fetchData.json();

          const url = process.env.REACT_APP_UAPI_URL;
          const method = "POST";
          const bodyData = { "id":e }

          const json = await HandleApi({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":authAccess });

          setOpenBackdrop(false);
          
          if(json){
            if(json.Status === "Success"){
              window.open(json.Message, " ");
              
            }
  
            if(json.Status === "Error"){
              setSnackRightEOpen(true);
              setSnackEError(json.Message)
            }
            
          }

        }catch(err){
          console.log(err);
          setSnackRightEOpen(true);
          setSnackEError(err);
        }




      }

  function decideRole( e, f, api, redirect ){
    try{
      console.log("e : ",e+ " f : ",f, "redirect : ",redirect, "api : ",api)
   
       if(e === "Card Lock/Unlock"){
         setEntityId(f)
         setDialogCardDetail(true);
         console.log("api : ",api)
        //  setLockApi( `${process.env.REACT_APP_UAPI_URL}${api}`);
         handleCardLockUnlock(f, api );
       }
   
       if(e === "Card Balance" ){
        console.log("api data : ",`${process.env.REACT_APP_UAPI_URL}`);
        
         fetchBalance(f, `${process.env.REACT_APP_UAPI_URL}${api}` );
       }
   
       if(e === "Card Details" ){
         getCardDetails(f, `${process.env.REACT_APP_UAPI_URL}${api}`)
       }
   
       if(e === "Card Transaction"){
         fetchTransitions(f, `${process.env.REACT_APP_UAPI_URL}${api}`, redirect)
         setCardTransId(f);
       }
   
       if(e === "Card SetLimit"){
         setInputLimitValueID(f) 
         setOpenLimit(true);
         getStatus(`${process.env.REACT_APP_UAPI_URL}${api}`, f);
         setCardsLimitId(f)
       }
   
       if(e === "Card Enable Txn"){
          setEnableTrans(true);
         setEnableTransID(f);
         setCardsLimitId(f)
         getCardStatus(f)


        //  setEnableTxnApi(`${process.env.REACT_APP_UAPI_URL}${api}`);
        //  enableTxn(f, api);
       }
   
       if(e === "Card SetPin" ){
         console.log("card pin called");
         setCardPin(f,`${process.env.REACT_APP_UAPI_URL}${api}`)
       }

       if(e === "Card KYC"){
        kycFormData(f);
       }  

       if(e === "Convert to Physical"){
        convertPhysical(f, `${process.env.REACT_APP_UAPI_URL}${api}` );
       }

    }catch(err){

    }
   // e = data.Action && f = row.id
    
  }

  const convertCard = async(f , api) => {

    try{

      setOpenBackdrop(true)
      // const fetchApi = await fetch(phyApi, {
      //   method:"POST",
      //   headers:{
      //     "Accept":"application/json",
      //     "Content-Type":"application/json",
      //     "Authorization":`Bearer ${authAccess}`
      //   },
      //   body:JSON.stringify({ "id":phyId })
      // });
  
      // const json = await fetchApi.json();
  
  
  
      const url = process.env.REACT_APP_UAPI_URL;
      const method = "POST";
      const bodyData = { "id":f }
  
      const json = await HandleApi({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":authAccess });
      setOpenBackdrop(false);
  
      console.log("phy json data : ",json);
      setPhyProgress(false);
      if(json){
        if(json.Status === "Success"){
          setSnackRightOpen(true)
          setSnackMessage(json.Message);
          cardData();
          setTimeout(() => {
            eraseConvertData();
            setConvertPhy(false)
          },800)
        }
  
        if(json.Status === "Error"){
          setSnackRightEOpen(true);
          setSnackEError(json.Message)
        }
      }
    }catch(err){  
      console.log("error : ",err)
      setSnackRightEOpen(true);
      setSnackEError(err)
    }

    

  }

  const eraseConvertData = () => {
    setAdd1('');
    setAdd2('');
    setAdd3('');
    setPhyCity('');
    setPhyState('');
    setPhyPin('');
  }

  const convertPhysical = (f, api) => {
    console.log("convert physical : ",f, " *******  ", api);
    // setConvertPhy(true);
    // setPhyApi(api);
    // setPhyId(f);

    convertCard(f , api)
  
  }

  const paginationStyle = {
    padding:20,
    width: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    
  };

  const handleChangePage = (event, newPage) => {
    console.log("handle page change : ",newPage);
    setPage(newPage);
  };


  const handleCatClick = (e) => {
    console.log("clicked item : ",e);
    setCatClick(e);
    setAnchorEl(null);
  }

  React.useEffect(() => {
    console.log("listened")
    if(catClick === '' ){
      console.log("listened 2")
      setCatClick("Eezib Transorp")
    }
  },[catClick])

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 82,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(45px)',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#385dff' : '#385dff',
      width: 32,
      height: 32,
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      borderRadius: 20 / 2,
    },
  }));

  const cardData = async() => {
    try{
      setFinalTable(true);

      const url = process.env.REACT_APP_UAPI_URL

      const apiUrl = `${url}/api/cardType`;
      const method = "POST";
      const bodyData = {"cardType":checked}
  
  
      const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData , "authAccess":authAccess });
  
    console.log("all card json data : ",json);
      
      setFinalTable(false);
  
      if(json){
        if(json.Status === "Success"){
          console.log("card type data : ",json.Message);
          setTableData(json.Message);
          //openConsentForm(json.Message);
        }
        if(json.Status === "Error"){
          setTableData(null);
        }
      }
    }catch(err){
      setSnackRightEOpen(true);
            setSnackEError(err);
            Cookies.remove("demoCAuth")
            navigate("/", { replace : true });
    }

  }

  React.useEffect(() => {
    cardData();
  },[checked]);

 // console.log("sliced data : ",tableData);

 console.log("current data : ",currentData);

 const openConsentForm = async(e) => {
  setCurrentData(e);
  setVerifyUser(e.name);
  setVerifyEmail(e.email_id);
  setverifyDob(e.dob);
  setVerifyAddress(e.PER_ADDRESS1);
  setVerifyAddress2(e.PER_ADDRESS2);
  setVerifyAddress3(e.PER_ADDRESS3);
  setCity(e.PER_CITY);
  setState(e.PER_STATE);
  setPinCode(e.PER_PIN);
  setOpenBackdrop(true)

  const url = process.env.REACT_APP_UAPI_URL
  const apiUrl = `${url}/api/viewConsent`;
  const method = "POST";
  const bodyData = { "id":e.id }


  const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData , "authAccess":authAccess });

  // const fetchApi = await fetch(`${url}/api/viewConsent`, {
  //   method:"POST",
  //   headers:{
  //     "Content-Type":"application/json",
  //     "Authorization":`Bearer ${authAccess}`
  //   },
  //   body:JSON.stringify({ "id":e.id })
  // });
  // const json = await fetchApi.json();
  console.log("json data : ",json);
  setOpenBackdrop(false);


  if(json){
    if(json.Status === "Success"){
      console.log(json.Message);
      setOpenConcent(true);
      setConcentData([json.Message])
      // <Box src={json.Message} component="img" sx={{ backgroundSize:"cover", position:"absolute", backgroundPosition:"center center" }} />
    }
    if(json.Status === "Error"){
      setSnackRightEOpen(true);
      setSnackEError(json.Message);
    }}}

 async function handleActivateCard(e){
  setProgress(true);
  setOpenBackdrop(true);

  const url = process.env.REACT_APP_UAPI_URL
  
  try{

  console.log(e)
    const url = process.env.REACT_APP_UAPI_URL
  const apiUrl = `${url}/api/Transregistration`;
  const method = "POST";
  const bodyData = {"id":e}


  const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData , "authAccess":authAccess });

  // const fetchApi = await fetch(`${url}/api/Transregistration`, {
  //   method:"POST",
  //   headers:{
  //     "Content-Type":"application/json",
  //     "Authorization":`Bearer ${authAccess}`
  //   },
  //   body:JSON.stringify({"id":e})
  // });
  // const json = await fetchApi.json();
  setProgress(false);
  setOpenBackdrop(false);
  console.log("activate card json : ",json);
  if(json){
    if(json.Status === "Error"){
      setSnackRightEOpen(true);
      setSnackEError(json.Message)
      setProgress(false)
    }
    if(json.Status === "Success"){
      setSnackRightOpen(true)
      setSnackMessage(json.Message);
      cardData();
    }
  }
  }catch(err){
    setSnackRightEOpen(true);
      setSnackEError(err)
  }

}

  const isDark = Cookies.get("darkMode");
  console.log('dark mode : ',isDark);

  console.log("otp value : ",otpValue);

  const verifyuserData = async(e) => {

    e.preventDefault();

    try{
      // const fetchApi = await fetch(`${progress.process.env.REACT_APP_UAPI_URL}/api/${currentData.url}`)
      setVerifyProg(true);
      const fetchData = await fetch(`${process.env.REACT_APP_UAPI_URL}${currentData?.url}`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json",
          "Authorization":`Bearer ${authAccess}`
        },
        body:JSON.stringify({ 
          "id":currentData?.id,
          "name":verifyUser,
          "email":verifyEmail,
          "dob":verifyDob,
          "addressinfo":[{ "address1": verifyAddress , "address2": verifyAddress2, "address3":verifyAddress3, "city":city, "state":state, "pincode":pincode }]
         })       
        })
        const json = await fetchData.json();
        setVerifyProg(false);
        console.log("otp status : ",json);
        if(json){
          if(json.Status === "Success"){
              setotpVerify(true);
              setVerifyDataDialog(false);
              setOtpEntityId(json.Message.sessionId);
              Cookies.remove("userName");
              Cookies.set("userName",json?.data.name)
              console.log( "entity id : ",json.Message.sessionId);
              setVerifyAddress("");
              setVerifyAddress2("");
              setVerifyAddress3("");
              setPinCode("");
              setState("");
              setCity("");
              cardData();
          }
          if(json.Status === "Error"){
            setSnackRightEOpen(true);
            setSnackEError(json.Message)
            setProgress(false)
          }
        }
    }catch(err){
      console.log(err);
      setSnackRightEOpen(true);
      setSnackEError(err)
      setProgress(false)
    }

  }

  console.log("********* entity id ********* : ",otpEntityId)
  // const url = process.env.REACT_APP_UAPI_URL


  const handleOtpVerify = async() => {
    setOtpProg(true);

    const url = process.env.REACT_APP_UAPI_URL
    const apiUrl = `${url}/api/cardActivation`;
    const method = "POST";
    const bodyData = { "id":currentData?.id, "otp":otpValue, "sessionId":otpEntityId ? otpEntityId : ""   }
  
  
    const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData , "authAccess":authAccess });

    // const fetchData = await fetch(`${url}/api/cardActivation`, {
    //   method:"POST",
    //   headers:{
    //     "Content-Type":"application/json",
    //     "Authorization":`Bearer ${authAccess}`
    //   },
    //   body:JSON.stringify({ "id":currentData?.id, "otp":otpValue, "sessionId":otpEntityId ? otpEntityId : ""   })
    // }); 
    // console.log({ "id":currentData?.id, "otp":parseInt(otpValue), "sessionId":otpEntityId ? otpEntityId : "" });
    // const json = await fetchData.json();
   
    console.log("json : ",json);

    setOtpProg(false);

    if(json){
      if(json.Status === "Success"){
          setSnackRightOpen(true);
          setSnackMessage(json.Message)
          setOtpValue("");
          setTimeout(() => {
            setotpVerify(false);
            cardData();
          },1000 )
      }
      if(json.Status === "Error"){
        setSnackRightEOpen(true);
        setSnackEError(json.Message);
        setOtpValue("");
      }
    }


  }

  console.log("otp entity id : ",otpEntityId)

  const errorMsg = () => {
    setSnackRightEOpen(true);
    setSnackEError("Technical issue, We are fixing it, Try again in sometime ")
  }

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  console.log("sliced data : ",slicedData);

  const handleOpenKyc = () => {
    setOPenKYC(false);
    setOpenBackdrop(false);
  }

  const handlePinCode = (e) => {

    const value = e.target.value;
    const regex = /^\d{0,6}$/;

    if(regex.test(value)){
      setPinCode(value)
    }

  }

  const handleTnxChange = (e) => {
    // (e) => setInputLimitValue(e.target.value)

    const value = e.target.value

    const regex = /^(?:[1-9]\d{0,5}|1000000|0)?$/;

    if(regex.test(value)){
      console.log("value : ",value)
      setInputLimitValue(value);
    }
  }



  const getStatus = async(api , f) => {

    setOpenBackdrop(true);

    console.log(" get status props : ",api , f)

    try{

      const method = "POST";
      const bodyData = { "id":f }
    
      const json = await HandleApi({"apiUrl":api, "method":method, "bodyData":bodyData ,"authAccess":authAccess });

      console.log("get status json : ",json);

      setOpenBackdrop(false);

      if(json){
        if(json.status === "success"){
          setCardLimitData(json.data);
        }
      }

    }catch(err){
      console.log("get status error : ",err)
    } 
  }

  const setLimits = async(id) => {

    setLimitCardProgress(true)

    console.log("curren card id : ",cardsLimitId)

    try{

      const url = process.env.REACT_APP_UAPI_URL
    const apiUrl = `${url}/api/setlimit`;
    const method = "POST";
    const bodyData = {
      "id": cardsLimitId,
      "data":
      {
          "Ecommerce": {
              "limit": ecommLimit >= 100 && ecommLimit !== undefined ? ecommLimit : cardLimitData?.Ecommerce?.limit,
          },
          "Point_of_Sale": {
              "limit": Pos >= 100 && Pos !== undefined ? Pos : cardLimitData?.Point_of_Sale?.limit,
          },
          "ATM": {
              "limit": Atm >= 100 && Atm !== undefined ? Atm : cardLimitData?.ATM?.limit,
          },
          "Contactless": {
              "limit": contactless >= 100  && contactless !== undefined ? contactless :  cardLimitData?.Contactless?.limit ,
          }
      }
  }
  

  // if(  ecommLimit < 100 || cardLimitData?.Ecommerce?.limit < 100 )
  if( ecommLimit ? ecommLimit < parseInt(cardLimitData?.Ecommerce?.minlimit) :  cardLimitData?.Ecommerce?.limit < parseInt(cardLimitData?.Ecommerce?.minlimit)   )
  {
    setSnackRightEOpen(true);
    setSnackEError(`E-Commerce value can't be less than ${cardLimitData?.Ecommerce?.minlimit}`);
    console.log("body data : ", bodyData);
    setLimitCardProgress(false);
    return;
  }

  else if( ecommLimit > parseInt(cardLimitData?.Ecommerce?.maxlimit) || parseInt(cardLimitData?.Ecommerce?.limit) >  parseInt(cardLimitData?.Ecommerce?.maxlimit) )
    {
      setSnackRightEOpen(true);
      setSnackEError(`E-Commerce Value Cannnot Be Greater Then ${cardLimitData?.Ecommerce?.maxlimit}`);
      console.log("body data : ", bodyData);
      setLimitCardProgress(false);
      return;
    }

  // else if( Pos < 100 || cardLimitData?.Point_of_Sale?.limit < 100 ){
    if( Pos ? Pos < parseInt(cardLimitData?.Point_of_Sale?.minlimit) :  cardLimitData?.Point_of_Sale?.limit < parseInt(cardLimitData?.Point_of_Sale?.limit) ){

    setSnackRightEOpen(true);
    setSnackEError(`Point Of Sale Value Can't be Less Then ${cardLimitData?.Point_of_Sale?.minlimit}`);
    console.log("body data : ", bodyData);
    setLimitCardProgress(false);
    return;
  }

  else if( Pos > parseInt(cardLimitData?.Point_of_Sale?.maxlimit) || cardLimitData?.Point_of_Sale?.limit >  parseInt(cardLimitData?.Point_of_Sale?.maxlimit) ){
    setSnackRightEOpen(true);
    setSnackEError(`Point Of Sale value Cannot be Greater than ${cardLimitData?.Point_of_Sale?.maxlimit}`);
    console.log("body data : ", bodyData);
    setLimitCardProgress(false);
    return;
  }



  else if( Atm > parseInt(cardLimitData?.ATM?.maxlimit) || cardLimitData?.ATM?.limit > parseInt(cardLimitData?.ATM?.maxlimit) ){
    setSnackRightEOpen(true);
    setSnackEError(`ATM value can't be Greater Then ${cardLimitData?.ATM?.maxlimit}`);
    setLimitCardProgress(false);
    return;
  }

  // else if( Atm < 100 || cardLimitData?.ATM?.limit < 100 ){
    if( Atm ? Atm < parseInt(cardLimitData?.ATM?.minlimit) :  cardLimitData?.Atm?.limit < parseInt(cardLimitData?.ATM?.minlimit) ){
    setSnackRightEOpen(true);
    setSnackEError(`ATM value can't be less than ${cardLimitData?.ATM?.minlimit}`);
    setLimitCardProgress(false);
    return;
  }


  // else if( contactless < 100 || cardLimitData?.Contactless?.limit < 100 ){
    if( contactless ? contactless < parseInt(cardLimitData?.Contactless?.minlimit) :  cardLimitData?.Contactless?.limit < parseInt(cardLimitData?.Contactless?.minlimit) ){
    setSnackRightEOpen(true);
    setSnackEError(`Contactless value can't be less than ${cardLimitData?.Contactless?.minlimit}`);
    setLimitCardProgress(false);
    return;
  }

  else if( contactless > parseInt(cardLimitData?.Contactless?.maxlimit) || cardLimitData?.Contactless?.limit > parseInt(cardLimitData?.Contactless?.maxlimit) ){
    setSnackRightEOpen(true);
    setSnackEError(`Contactless Value Cannot Be Greater Than ${cardLimitData?.Contactless?.maxlimit}`);
    setLimitCardProgress(false);
    return;
  }

    const json = await HandleApi({"apiUrl":apiUrl, "method":method, "bodyData":bodyData , "authAccess":authAccess });
    setLimitCardProgress(false);

    if(json){
      if(json.status === "success"){
        if(json.message.Ecommerce){
          setSnackRightOpen(true);
          setSnackMessage(`E-Commerce ${ json.message.Ecommerce ? json.message.Ecommerce : null }`)
        }
        
        if(json.message.Contactless){
          setTimeout(() => {         
            setSnackRightOpen(true);
            setSnackMessage(`Contactless ${ json.message.Contactless ? json.message.Contactless : null }`)
          }, 2000)
        }

        if(json.message.ATM){
          setTimeout(() => {
            setSnackRightOpen(true);
            setSnackMessage( ` ATM ${ json.message.ATM ? json.message.ATM : null  }` );
            setOpenLimit(false);
          }, 3200)
        }


        if(json.message.Point_of_Sale){
          setTimeout(() => {
            setSnackRightOpen(true);
            setSnackMessage( `Point_of_Sale ${json.message.Point_of_Sale ? json.message.Point_of_Sale : null }` )
          }, 4500)
        }

      }
      if(json.status === "error"){
       
        if(json.message.Ecommerce){
          setSnackRightOpen(true);
          setSnackMessage(`E-Commerce ${ json.message.Ecommerce ? json.message.Ecommerce : null }`)
        }

        if(json.message.Contactless){
          setTimeout(() => {         
            setSnackRightOpen(true);
            setSnackMessage(`Contactless ${ json.message.Contactless ? json.message.Contactless : null }`)
          }, 2000)
        }


        if(json.message.ATM){
          setTimeout(() => {
            setSnackRightOpen(true);
            setSnackMessage( ` ATM ${ json.message.ATM ? json.message.ATM : null  }` );
            setOpenLimit(false);
          }, 3200)
        }

        if(json.message.Point_of_Sale){
          setTimeout(() => {
            setSnackRightOpen(true);
            setSnackMessage( `Point_of_Sale ${json.message.Point_of_Sale ? json.message.Point_of_Sale : null }` )
          }, 4500)
        }
      }

    }

  }catch(err){
    console.log("error")
  }
}

  const  marks1 = [
    {
      value:100
    },
    {
      value : cardLimitData?.Ecommerce?.limit
    }
  ]

  console.log("slider value limits ******* : ",{"ecom list": ecommLimit , "Pos":Pos, "Atm":Atm, "contatcless":contactless });

  const getCardStatus = async(f) => {

    const url = process.env.REACT_APP_UAPI_URL
    const apiUrl = `${url}/api/getstatus`;
    const method = "POST";
    const bodyData = {  "id": f }

    const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, "bodyData":bodyData });

    console.log("json data : ",json);

    if(json){
      if(json.status === "success"){
        setEnableTxnData(json.data);

        if( json.data.Ecommerce.status === true ){
          setEomCheck(1)
        }
        else if ( json.data.Ecommerce.status === false ) {
          setEomCheck(0)
        }

         if( json.data.Point_of_Sale.status === false ){
          setPosCheck(0)
        }

        else if( json.data.Point_of_Sale.status === true ){
          setPosCheck(1)
        }

         if( json.data.ATM.status === true ){
          setAtmCheck(1)
        }

        else if( json.data.ATM.status === false ){
          setAtmCheck(0)
        }

         if( json.data.Contactless.status === false ){
          setContactlessCheck(0)
        }

        else if( json.data.Contactless.status === true ){
          setContactlessCheck(1)
        }

      }
    }

  }

  React.useEffect(() => {
    },[cardsLimitId])

  console.log("********** ecom check *********",EcomCheck);



  const setTransactionEnable = async() => {

    try{

      console.log("transaction enable data : ",enableTxnData);
  
      console.log("button state check :",{"ecomerce":EcomCheck, "pos check":PosCheck, "atm check":atmCheck, "contactless check":contactlessCheck})
  
      setLimitCardProgress(true)
  
  
      const url = process.env.REACT_APP_UAPI_URL
      const apiUrl = `${url}/api/setstatus`;
      const method = "POST";
      const bodyData = {  
        "id":cardsLimitId,
        "data":{
        "Ecommerce": {
              "status": EcomCheck === 1 ? true : false,
          },
          "Point_of_Sale": {
              "status": PosCheck === 1 ? true : false,
          },
          "ATM": {
              "status": atmCheck === 1 ? true : false,
  
          },
          "Contactless": {
              "status": contactlessCheck === 1 ? true : false,
          }
      }
    }
  
      const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, "bodyData":bodyData });
  
      console.log("json data : ",json);
  
      setLimitCardProgress(false);
  
      if(json){
        if(json.status === "success"){

          if(json.message.Ecommerce){
            setSnackRightOpen(true);
            setSnackMessage(`E-Commerce ${json.message.Ecommerce ? json.message.Ecommerce : null }`)
            setTimeout(() => {
              setEnableTrans(false)
              setSnackRightOpen(false);   
            },1500)
          }

  
          if(json.message.Contactless){
            setTimeout(() => {         
              setSnackRightOpen(false);   
              setSnackRightOpen(true);
              setSnackMessage(`Contactless ${json.message.Contactless}`)
            }, 2000)
          }
  
  
          if(json.message.ATM){
            setTimeout(() => {
              setSnackRightOpen(false);   
              setSnackRightOpen(true);
              setSnackMessage( ` ATM ${json.message.ATM}` )
            }, 4500)
          }
  
          if(json.message.Point_of_Sale){
            setTimeout(() => {
              setSnackRightOpen(false);   
              setSnackRightOpen(true);
              setSnackMessage( ` Point_of_Sale ${json.message.Point_of_Sale}` )
            }, 6000)
          }
  
        }
        if(json.status === "error"){
       
          if(json.message.Ecommerce){
            setSnackRightOpen(true);
            setSnackMessage(`E-Commerce ${ json.message.Ecommerce ? json.message.Ecommerce : null }`)
          }
  
          if(json.message.Contactless){
            setTimeout(() => {         
              setSnackRightOpen(true);
              setSnackMessage(`Contactless ${ json.message.Contactless ? json.message.Contactless : null }`)
            }, 2000)
          }
  
  
          if(json.message.ATM){
            setTimeout(() => {
              setSnackRightOpen(true);
              setSnackMessage( ` ATM ${ json.message.ATM ? json.message.ATM : null  }` );
              setOpenLimit(false);
            }, 3200)
          }
  
          if(json.message.Point_of_Sale){
            setTimeout(() => {
              setSnackRightOpen(true);
              setSnackMessage( `Point_of_Sale ${json.message.Point_of_Sale ? json.message.Point_of_Sale : null }` )
            }, 4500)
          }
        }
      }
    }catch(err){
      console.log(err);
    }


  }

  const handleEcomLimmit = (e) => {
    const value = e.target.value;
    const regex = /^(?:[0-9]{1,5}|[12][0-9]{5}|300000)$/; 

    // Allow the user to clear the input
    if (regex.test(value)) {
        setEcomLimit(value);
    }
};

const handlePosLimit = (e) => {

  const value = e.target.value;
  const regex = /^(?:[0-9]{1,5}|[12][0-9]{5}|300000)$/; 

  if (regex.test(value)) {
      setPos(value);
  }
}

const handleAtmLimit = (e) => {

  const value = e.target.value;
  const regex = /^(?:[0-9]{1,5}|[12][0-9]{5}|300000)$/; 

  if (regex.test(value)) {
      setAtm(value);
  }
}

const handleContactLimit = (e) => {

  const value = e.target.value;
  const regex = /^(?:[0-9]{1,5}|[12][0-9]{5}|300000)$/; 

  if (regex.test(value)) {
      setContactless(value);
  }
}

  const handleFocus = (event)  => {
    event.target.select();
}

const downloadTransaction = async() => {

            {/* TransfetchtransactionGpr */}

            setTxnProg(true);

            const url = process.env.REACT_APP_UAPI_URL
            const apiUrl = `${url}/api/TransfetchtransactionGpr`;
            const method = "POST";
            const bodyData = {
              "id":cardTransId,
              "fromDate":startDate,
              "toDate":endDate
          }

       const json = await HandleApi({"apiUrl":apiUrl, "method":method, "authAccess":authAccess, "bodyData":bodyData });

       console.log(json);

       setTxnProg(false);

       if(json){
        if(json.Status === "Success"){
          setReportData(json.Message)
        }

        if(json.Status === "Error"){
          setSnackRightEOpen(true);
          setSnackEError(json.Message)
        }
       }


}

const headers = Object.keys( transSlicedData ? transSlicedData[0] : {}); 

//console.log("report data : ",reportData?.length);

const generateExcel = () => {
  if (reportData?.length === 0) {
    alert("No data available to generate Excel");
    return;
  }

  // Create a new workbook
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(reportData);

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Write the workbook to a file
  XLSX.writeFile(workbook, 'tnx download.xlsx');

  // alert("Excel file generated successfully");
};



const generatePdf = () => {
  if (reportData?.length === 0) {
    alert("No data available to generate PDF");
    return;
  }

  const pdf = new jsPDF();
  const keys = Object.keys(reportData[0]);

  // Set initial x and y position for content
  let xPos = 10;
  let yPos = 10;

  // Set cell width and height
  const cellWidth = 22;
  const minHeight = 8;

  // Set font size
  const fontSize = 6;
  pdf.setFontSize(fontSize);

  // Add title
  const title = "Eezib Transaction Data";
  const titleFontSize = 15;
  pdf.setFontSize(titleFontSize);
  const pageWidth = pdf.internal.pageSize.getWidth();
  const titleWidth = pdf.getTextDimensions(title).w;
  const titleXPos = (pageWidth - titleWidth) / 2;
  pdf.text(titleXPos, yPos, title);

  // Adjust y position to start adding table data after title
  yPos += titleFontSize + 10; // 10 units of padding below the title

  // Add headers
  pdf.setFontSize(fontSize);
  keys.forEach((key, index) => {
    pdf.rect(xPos, yPos, cellWidth, minHeight, 'S');
    pdf.text(xPos + 1, yPos + 5, key);
    xPos += cellWidth;
  });

  // Reset x position and increment y position
  xPos = 10;
  yPos += minHeight;

  // Add data
  reportData?.forEach(data => {
    keys.forEach((key, index) => {
      const value = data[key] || '';
      const valueLines = pdf.splitTextToSize(value.toString(), cellWidth - 2);
      const cellHeight = Math.max(minHeight, valueLines.length * fontSize / 2); // Adjust for font size
      pdf.text(xPos + 1, yPos + 5, valueLines);
      pdf.rect(xPos, yPos, cellWidth, cellHeight, 'S');
      xPos += cellWidth;
    });
    xPos = 10;
    yPos += minHeight;
  });

  pdf.save('tnxData.pdf');
};

  const handleCross = () => {
    setDownloadTnx(false);
    setStartDate("");
    setEndDate("");
    setReportData()
  }




  return (
    <Box className="topupContainer">
        <Header/>
        <Box sx={{ display:'flex', alignItems:"center", justifyContent:'center', flexDirection:"column" }} >
        <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap:1, margin:"1.2rem" }} >
        <Typography sx={{ fontFamily:"montserrat", fontWeight: checked ? 500  :600, color: checked ?  "black" : "#5c1880", textShadow: checked ?  null : '1px 2px 10px white', fontSize:{xs:"0.8rem", lg:"1.2rem"} }}  >Prepaid Card</Typography>


    <Switch
              className="switch"
              checked={checked === 1}
              onChange={handleCheckedSwitch}
              sx={(theme) => ({
                '--Switch-thumbShadow': '0 3px 7px 0 #a4bbfc',
                '--Switch-thumbSize': '25px',
                '--Switch-trackWidth': '61px',
                '--Switch-trackHeight': '30px',
                '--Switch-trackBackground': theme.vars.palette.background.level3,
                [`& .${switchClasses.thumb}`]: {
                  transition: 'width 0.2s, left 0.2s',
                },
                '&:hover': {
                  '--Switch-trackBackground': theme.vars.palette.background.level3,
                },
                '&:active': {
                  '--Switch-thumbWidth': '33px',
                },
                [`&.${switchClasses.checked}`]: {
                  '--Switch-trackBackground': '#e3e3e3',
                  '&:hover': {
                    '--Switch-trackBackground': '#e3e3e3',
                  },
                },
              })}
            />  


        <Typography sx={{ fontFamily:"montserrat", fontWeight: checked ? 600 : 500, color: checked ? "#5c1880" : "black", textShadow: checked ? '1px 2px 10px white': null, fontSize:{xs:"0.8rem", lg:"1.2rem"} }}  >Reloadable Card</Typography>

        </Box>

        


        

        {
          finalTable ? <CircularProgress size={100} thickness={1} sx={{ marginTop:"2rem" }} /> :   <> <TableContainer elevation={16} component={Paper} sx={{ marginTop:1.5, width:{lg:"88%", xs:"95%" }}} >
          <Table sx={{ backgroundColor:'white' }} aria-label="simple table">
              <TableHead>
              <TableRow sx={{ backgroundColor:'#219bff' }}>
                  <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"1rem", xs:"0.8rem" } }} align="center"  >Sr. No</TableCell>
                  <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"1rem", xs:"0.8rem" } }} align="center"  >Activation Date</TableCell>
                  <TableCell  sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"1rem", xs:"0.8rem" }, display:'flex', alignItems:'center', justifyContent:'center', cursor:'pointer' }} onClick={(event) => setAnchorEl(event.currentTarget)}  align="center">Card Name<ExpandMoreIcon/></TableCell>
                  <TableCell  sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"1rem", xs:"0.8rem" } }}  align="center">Card Type</TableCell>
                  <TableCell  sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"1rem", xs:"0.8rem" } }}  align="center">Kit No</TableCell>


                  {/* <TableCell sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:"1rem" }}   align="center">subcategory</TableCell> */}
                  <TableCell  sx={{ fontFamily:'montserrat', fontWeight:600 , color:'white', fontSize:{lg:"1rem", xs:"0.8rem" },  position:{xs:"sticky", md:null, lg:null}, right:{xs:0, md:null, lg:null}, zIndex:{xs:3, md:null, lg:null} , backgroundColor:{xs:"#2196f3", md:"transparent", lg:"transparent"} }}  align="center">Action</TableCell>
              </TableRow>
              </TableHead>
              
              <TableBody>
               { 
              Array.isArray(  slicedData ) && slicedData?.map((row, index) => {
                    const actions = row.json_format ? JSON.parse( row.json_format) : null;
                   // console.log("insider data : ",row)
                   // console.log(actions)
                   
                return (
                      <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }} >
                          <TableCell align="center" sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:{lg:"1rem", xs:"0.8rem" }  }}  >{  startIndex + index + 1}</TableCell>
                          <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:{lg:"1rem", xs:"0.8rem" }  }}  >{ row.activation_date }</TableCell>
                          <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:{lg:"1rem", xs:"0.8rem" }  }}  >{row.category_name}</TableCell>
                          <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:{lg:"1rem", xs:"0.8rem" }  }}  >{row.card_type} Card</TableCell>
                          <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:row.REF_NO ? "0.9rem" : "0.8rem" , color:!row.REF_NO ? "#adadac":"black" }}  >{ row.REF_NO ? row.REF_NO : "--"}</TableCell>

                          {/* <TableCell align='center' sx={{ fontFamily:'montserrat', fontWeight:600 , fontSize:"0.9rem"  }}  >{ row.sub_category_name  }</TableCell> */}
                          <TableCell onClick={() => navigate()} align='center' sx={{ fontFamily:'montserrat', fontWeight:500 , fontSize:"0.9rem",  position:{xs:"sticky", md:null, lg:null}, right:{xs:0, md:null, lg:null}, zIndex:{xs:3, md:null, lg:null} , backgroundColor:{xs:"white", md:"transparent", lg:"transparent"}  }}  > 
                          {/* { row.REF_NO === null ? <Button size='small' variant='contained' onClick={() => handleActivateCard(row.id)} sx={{ fontFamily:'montserrat', fontWeight:500 }} >Activate Card </Button> :    */}
                          { row.REF_NO === null ? <Button size='small' variant='contained' onClick={() => row?.card_name === "Prepaid" ? handleActivateCard(row.id) : row?.card_name === "Relodable" ? openConsentForm(row) : null } sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:{xs:"0.5rem", sm:"1rem" , md:"1rem", lg:"1rem", xl:"1rem" } }} >Activate Card </Button> :  
                           (
                            isXs ? <MenuIcon onClick={(event) => handleMenuClick( event, actions, row.id )} sx={{ color:"#969696" }} /> :
                          Array.isArray(actions) && actions?.map((data, index) => {
                           
                           return (
                           <>
                           
                             <Tooltip key={data.Action} title={data.Action} >
                             <Box component='img' onClick={() => decideRole( data.Action , row.id , data.Api , data.PType ) } src={`${process.env.REACT_APP_UAPI_URL}${data.Img}`  } sx={{ width:"1.4rem", marginLeft:1, cursor:'pointer' }} />
                             </Tooltip>
                           </>
                           )
                           } )
                          )
                          }
                          {/* <Tooltip title="Card Details" onClick={() => getCardDetails(row.id)}  >  <Box component='img' src={cardDetails} sx={{ width:'1.5rem', margin:0.5, cursor:'pointer' }} /> </Tooltip>
                          <Tooltip onClick={() => handleLockUnlock(row.entity_id)} title="Card Lock/Unlock" >   <Box component='img' src={blackunblock} sx={{ width:'1.5rem', margin:0.5, cursor:'pointer' }} /> </Tooltip>
                          <Tooltip title="Fetch Transactions" >   <Box component='img' src={fetchBaalance} sx={{ width:'1.5rem', margin:0.5, cursor:'pointer' }} /> </Tooltip>
                          <Tooltip title="Set Pin" >   <Box component='img' src={setpin} sx={{ width:'1.5rem', margin:0.5, cursor:'pointer' }} /> </Tooltip>
                          */}
                          </TableCell> 

                      </TableRow>
                )
              }) 
              } 
              </TableBody>
          </Table>
      </TableContainer>
      {
        !slicedData  ? <Typography sx={{ fontFamily:"montserrat", fontSize:{ lg:"1.7rem", sm:"1.5rem", md:"1.7rem", xs:"1rem" }, fontWeight:700, textTransform:'capitalize', mt:2, color:"#2e61bf", textShadow:'2px 2px 8px white', marginTop:"2rem" }} > no data available at moment..</Typography>  : 
         <Pagination
        style={paginationStyle}
        variant="outlined"
        color="primary"
        count={ Math.ceil(handleUserTable()?.length / 10)}
        page={page}
        onClick={() => window.scroll(0,10)}
        onChange={handleChangePage}
        sx={{ marginBottom:5 }}
          />
      }
      
     

            </> 
        }

      

                </Box>
                {/* <Dialog
                open={dialogCardDetail}
                onClose={handleLockClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <CloseIcon onClick={() => setDialogCardDetail(false)} sx={{ cursor:'pointer',marginLeft:"auto", backgroundColor:'red', color:'white', fontSize:"1.3rem" }} />
                <DialogTitle sx={{ fontFamily:"montserrat", fontWeight:500, textAlign:'center' }} id="alert-dialog-title">
                  {"Change Card Status.."}
                </DialogTitle>
                <DialogContent >

                 <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:2 }} >
                 <FormControl sx={{ marginTop:2, width:"20rem" }} >
                    <InputLabel sx={{ fontFamily:"montserrat", fontWeight:500 }} id="demo-simple-select-label">Select Status</InputLabel>
                    <Select
                    sx={{ fontFamily:"montserrat", fontWeight:500 }}  
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={lockStatus}
                      label="Select Status"
                      onChange={handleLockChange}
                    >
                      <MenuItem sx={{ fontFamily:"montserrat", fontWeight:500 }} className='commnTextClass' value="L">Lock</MenuItem>
                      <MenuItem sx={{ fontFamily:"montserrat", fontWeight:500 }} value="U">Unlock</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField onChange={(e) => setCardLockReason(e.target.value)} sx={{ width:"20rem", fontFamily:"montserrat", fontWeight:500 }} label='Enter Reason' ></TextField>
                    {
                      cardLockProgress ? <CircularProgress /> : 
                  <Button disabled={ ! (cardLockReason && lockStatus) } fullWidth variant='contained' onClick={() => handleCardLockUnlock()} sx={{ fontFamily:"montserrat", fontWeight:500, marginTop:2 }} >submit form</Button>
                    }

                 </Box>
                </DialogContent>
              </Dialog> */}


              <Dialog
              open={openLimit}
              onClose={handleLimitClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "800px",  // Set your width here
                  },
                },
              }}
            >
              <DialogContent 
              // sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', margin:0, padding:1 }}
               >

               <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', margin:0, padding:0 }} >
                <Box component='img' src={eezibLogo} sx={{ width:"3rem" }} />
                <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:"1.2rem" }} > Set Card Limit </Typography>
                <CloseIcon onClick={() => setOpenLimit(false) } sx={{ backgroundColor:'red', color:'white',fontSize:'1.2rem', cursor:'pointer'  }} />
               </Box>

               <Divider sx={{ mt:"1rem" }} />
               <Box>

                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', flexDirection:isXs ? "column" : "row" }} >
                  <Box component="img" src={ecomImg} sx={{ width:"12rem" }} />
                  <Box sx={{ display:'flex', mt:3, alignItems:'center', gap:{lg:"1rem", sm:"1.5rem", xs:"0.8rem" }, flexDirection:{ lg:"column", md:"column", sm:"column", xs:"column" } }} >
                    <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{ lg:"1.1rem", sm:"1rem", md:"1.1rem", xs:"0.9rem" }, borderRadius:'5rem 5rem 5rem 5rem', backgroundColor:"#bdd3fc", padding:"0.5rem 1rem" , width:"8rem", textAlign:'center' }} > E Commerce</Typography> 
                    <Box>
                    <Slider                    
                    //  defaultValue={ cardLimitData?.Ecommerce?.limit }
                    key={cardLimitData?.Ecommerce?.maxlimit}
                    min={cardLimitData?.Ecommerce?.minlimit}
                    max={cardLimitData?.Ecommerce?.maxlimit}
                    aria-label="Small"
                    sx={{ width:{ lg:"25rem", sm:"22rem", md:"25rem", xs:"10rem" } }}
                    onChange={handleSliderChange}
                    value={ecommLimit === undefined ? cardLimitData?.Ecommerce?.limit : ecommLimit }
                    marks={marks1}
                    valueLabelDisplay="auto"
                    disabled={ cardLimitData?.Ecommerce?.applicable === 0 }
                    />    
                    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} >
                    <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:"#696969", fontSize:"0.8rem" }} >{cardLimitData?.Ecommerce?.minlimit} min</Typography>
                    <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:"#696969", fontSize:"0.8rem" }} >{cardLimitData?.Ecommerce?.maxlimit} max</Typography>           
                      </Box>   
                    </Box>                   
                <TextField variant='outlined' disabled={ cardLimitData?.Ecommerce?.applicable === 0 } onFocus={handleFocus} type="number" id="standard-basic" onChange={ handleEcomLimmit } value={ ecommLimit ? ecommLimit : cardLimitData?.Ecommerce?.limit } size='small' sx={{ width:"10rem", backgroundColor:'#f2f2f2', textAlign:'center' ,"input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                WebkitAppearance: "none", margin: 0,}, "input[type=number]": { MozAppearance: "textfield", }, }}  />                  
                </Box>

                </Box>

                <Divider variant='middle' sx={{ marginTop:"1rem" }} />

                <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', flexDirection:isXs ? "column" : "row" }} >

                <Box component="img" src={posImg} sx={{ width:"12rem" }} />

                <Box sx={{ display:'flex', mt:3, alignItems:'center', gap:{lg:"2rem", sm:"1.5rem", xs:"0.8rem" }, flexDirection:{ lg:"column", md:"column", sm:"column", xs:"column" } }} >
                <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{ lg:"1.1rem", sm:"1rem", md:"1.1rem", xs:"0.9rem" }, borderRadius:'5rem 5rem 5rem 5rem', backgroundColor:"#bdd3fc", padding:"0.5rem 1rem" , width:"8rem", textAlign:'center' }} >Point Of Sale</Typography> 
                <Box>

                    <Slider
                    // defaultValue={ cardLimitData?.Point_of_Sale?.limit?.toString() }
                    key={cardLimitData?.Point_of_Sale?.maxlimit}
                    min={100}
                    max={cardLimitData?.Point_of_Sale?.maxlimit}
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    sx={{ width:{ lg:"25rem", sm:"22rem", md:"25rem", xs:"10rem" } }}
                    onChange={handleSliderChangePos}
                    value={Pos === undefined ? cardLimitData?.Point_of_Sale?.limit : Pos  }
                    disabled={ cardLimitData?.Point_of_Sale?.applicable === 0 }

                    />
                    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} >
                    <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:"#696969", fontSize:"0.8rem" }} >{cardLimitData?.Point_of_Sale?.minlimit} min</Typography>
                    <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:"#696969", fontSize:"0.8rem" }} >{cardLimitData?.Point_of_Sale?.maxlimit} max</Typography>           
                      </Box> 
                    </Box>
                  <TextField disabled={ cardLimitData?.Ecommerce?.applicable === 0 } onFocus={handleFocus} type="number" onChange={ handlePosLimit } value={ Pos ? Pos : cardLimitData?.Point_of_Sale?.limit } id="standard-basic" size='small' sx={{ width:"10rem", backgroundColor:'#f2f2f2', "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                
                WebkitAppearance: "none", margin: 0,}, "input[type=number]": { MozAppearance: "textfield", }, }} variant="outlined" />                  
                  </Box>
                  </Box>

                  <Divider variant='middle' sx={{ marginTop:"1rem" }} />

                  <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', flexDirection:isXs ? "column" : "row" }} >

                    <Box component="img" src={atmImg} sx={{ width:"12rem" }} />   
                  <Box sx={{ display:'flex', mt:3, alignItems:'center', gap:{lg:"2rem", sm:"1.5rem", xs:"0.8rem" }, flexDirection:{ lg:"column", md:"column", sm:"column", xs:"column" } }} >
                  <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{ lg:"1.1rem", sm:"1rem", md:"1.1rem", xs:"0.9rem" }, borderRadius:'5rem 5rem 5rem 5rem', backgroundColor:"#bdd3fc", padding:"0.5rem 1rem" , width:"8rem", textAlign:'center' }} >ATM</Typography> 
                  <Box>

                    
                    <Slider
                    // defaultValue={70}
                    key={ cardLimitData?.ATM?.maxlimit }
                    min={100}
                    max={ cardLimitData?.ATM?.maxlimit }
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    sx={{ width:{ lg:"25rem", sm:"22rem", md:"25rem", xs:"10rem" } }}
                    onChange={handleSliderChangeAtm}
                    value={Atm === undefined ? cardLimitData?.ATM?.limit :  Atm  }
                    disabled={ cardLimitData?.ATM?.applicable === 0 }

                  />
                  <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} >
                      <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:"#696969", fontSize:"0.8rem" }} >{cardLimitData?.ATM?.minlimit} min</Typography>
                     <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:"#696969", fontSize:"0.8rem" }} >{ cardLimitData?.ATM?.maxlimit } max</Typography>           
                      </Box> 
                      </Box>
                  <TextField disabled={ cardLimitData?.Ecommerce?.applicable === 0 } onFocus={handleFocus} type="number"  onChange={ handleAtmLimit } value={ Atm ? Atm : cardLimitData?.ATM?.limit } id="standard-basic" size='small' sx={{ width:"10rem", backgroundColor:'#f2f2f2', "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                  WebkitAppearance: "none", margin: 0,}, "input[type=number]": { MozAppearance: "textfield", }, }} variant="outlined" />                  
                  </Box>
                  </Box>

                  <Divider variant='middle' sx={{ marginTop:"1rem" }} />

                  <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly', flexDirection:isXs ? "column" : "row" }} >

                  <Box component="img" src={contactLess} sx={{ width:"12rem" }} />

                  <Box sx={{ display:'flex', mt:3, alignItems:'center', gap:{lg:"2rem", sm:"1.5rem", xs:"0.8rem" }, flexDirection:{ lg:"column", md:"column", sm:"column", xs:"column" } }} >
                  <Typography sx={{ fontFamily:"montserrat", fontWeight:500, fontSize:{ lg:"1.1rem", sm:"1rem", md:"1.1rem", xs:"0.9rem" }, borderRadius:'5rem 5rem 5rem 5rem', backgroundColor:"#bdd3fc", padding:"0.5rem 1rem" , width:"8rem", textAlign:'center' }} >Contactless</Typography> 
                      <Box>

                      
                    <Slider            
                      // defaultValue={7000}
                      key={ cardLimitData?.Contactless?.maxlimit }
                      min={100}
                      max={ cardLimitData?.Contactless?.maxlimit }
                      aria-label="Small"
                      valueLabelDisplay="auto"
                      sx={{ width:{ lg:"25rem", sm:"22rem", md:"25rem", xs:"10rem" } }}
                      onChange={handleSliderChangeContact}
                      value={contactless === undefined ? cardLimitData?.Contactless?.limit : contactless }
                      disabled={ cardLimitData?.Contactless?.applicable === 0 }

                    />

                    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' }} >
                    <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:"#696969", fontSize:"0.8rem" }} >{cardLimitData?.Contactless?.minlimit} min</Typography>
                    <Typography sx={{ fontFamily:'montserrat', fontWeight:500, color:"#696969", fontSize:"0.8rem" }} >{ cardLimitData?.Contactless?.maxlimit } max</Typography>           
                      </Box>

                    </Box>
                     <TextField disabled={ cardLimitData?.Ecommerce?.applicable === 0 } onFocus={handleFocus} type="number" onChange={ handleContactLimit } value={contactless ? contactless : cardLimitData?.Contactless?.limit } id="standard-basic" size='small' sx={{ width:"10rem", backgroundColor:'#f2f2f2',  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                
                WebkitAppearance: "none", margin: 0,}, "input[type=number]": { MozAppearance: "textfield", }, }} variant="outlined" />                  
                  </Box>


                </Box>
                </Box>
                  <Button disabled={ limitCardProgress } fullWidth sx={{ fontFamily:'montserrat', fontWeight:500, marginTop:"2rem" }} onClick={(e) => setLimits()}  variant="contained" > { limitCardProgress ? "submitting Data.." : "Submit Data" }</Button>
                    

              </DialogContent>
            </Dialog>













            <Dialog
              open={enableTrans}
              onClose={handleTransClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "400px",  // Set your width here
                  },
                },
              }}
            >
              <CloseIcon onClick={() => setEnableTrans(false) } sx={{ backgroundColor:'red', color:'white',fontSize:'1.2rem', cursor:'pointer', position:"absolute", right:0 }} />
              <DialogTitle sx={{ fontFamily:"montserrat", fontWeight:500 , textAlign:'center'}} id="alert-dialog-title">
                {"Enable Online Transaction"}
              </DialogTitle>
              <Divider variant="middle" />
              <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:2,  }} >
              
             <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', gap:5 }} >

             <Typography sx={{ fontFamily:"montserrat", fontWeight:500, gap:{lg:"2rem", sm:"1.5rem", xs:"0.8rem" }, flexDirection:{ lg:"row", md:"row", sm:"column", xs:"column" }, backgroundColor:"#d1d1d1", padding:1.50, borderRadius:'5rem 5rem 5rem 5rem', width:{lg:"10rem", sm:"9rem", md:"10rem", xs:"8rem" }, textAlign:'center' }} > ECommerce </Typography>
             <Switch
              className="switch"
              checked={EcomCheck === 1}
              onChange={handleEcomCheck}
              disabled={ enableTxnData?.Ecommerce?.applicable === 0 }
              sx={(theme) => ({
                '--Switch-thumbShadow': '0 3px 7px 0 #a4bbfc',
                '--Switch-thumbSize': '25px',
                '--Switch-trackWidth': '61px',
                '--Switch-trackHeight': '30px',
                '--Switch-trackBackground': theme.vars.palette.background.level3,
                [`& .${switchClasses.thumb}`]: {
                  transition: 'width 0.2s, left 0.2s',
                },
                '&:hover': {
                  '--Switch-trackBackground': theme.vars.palette.background.level3,
                },
                '&:active': {
                  '--Switch-thumbWidth': '33px',
                },
                [`&.${switchClasses.checked}`]: {
                  '--Switch-trackBackground': '#5af26b',
                  '&:hover': {
                    '--Switch-trackBackground': '#5af26b',
                  },
                },
              })}
            />
             </Box>
             

            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', gap:5 }} >

            <Typography sx={{ fontFamily:"montserrat", fontWeight:500, gap:{lg:"2rem", sm:"1.5rem", xs:"0.8rem" }, flexDirection:{ lg:"row", md:"row", sm:"column", xs:"column" }, backgroundColor:"#d1d1d1", padding:1.50, borderRadius:'5rem 5rem 5rem 5rem', width:{lg:"10rem", sm:"9rem", md:"10rem", xs:"8rem" }, textAlign:'center' }} > Point Of Sale </Typography>
              
              <Switch
              className="switch"
              checked={ PosCheck === 1 }
              onChange={handlePosCheck}
              disabled={ enableTxnData?.Point_of_Sale?.applicable === 0 }
              sx={(theme) => ({
                '--Switch-thumbShadow': '0 3px 7px 0 #a4bbfc',
                '--Switch-thumbSize': '25px',
                '--Switch-trackWidth': '61px',
                '--Switch-trackHeight': '30px',
                '--Switch-trackBackground': theme.vars.palette.background.level3,
                [`& .${switchClasses.thumb}`]: {
                  transition: 'width 0.2s, left 0.2s',
                },
                '&:hover': {
                  '--Switch-trackBackground': theme.vars.palette.background.level3,
                },
                '&:active': {
                  '--Switch-thumbWidth': '33px',
                },
                [`&.${switchClasses.checked}`]: {
                  '--Switch-trackBackground': '#5af26b',
                  '&:hover': {
                    '--Switch-trackBackground': '#5af26b',
                  },
                },
              })}
            />

              </Box>



              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', gap:5 }} >

              <Typography sx={{ fontFamily:"montserrat", fontWeight:500, gap:{lg:"2rem", sm:"1.5rem", xs:"0.8rem" }, flexDirection:{ lg:"row", md:"row", sm:"column", xs:"column" }, backgroundColor:"#d1d1d1", padding:1.50, borderRadius:'5rem 5rem 5rem 5rem', width:{lg:"10rem", sm:"9rem", md:"10rem", xs:"8rem" }, textAlign:'center' }} > ATM </Typography>

              <Switch
              className="switch"
              checked={ atmCheck === 1 }
              onChange={ handleAtmCheck }
              disabled={ enableTxnData?.ATM?.applicable === 0 }
              sx={(theme) => ({
                '--Switch-thumbShadow': '0 3px 7px 0 #a4bbfc',
                '--Switch-thumbSize': '25px',
                '--Switch-trackWidth': '61px',
                '--Switch-trackHeight': '30px',
                '--Switch-trackBackground': theme.vars.palette.background.level3,
                [`& .${switchClasses.thumb}`]: {
                  transition: 'width 0.2s, left 0.2s',
                },
                '&:hover': {
                  '--Switch-trackBackground': theme.vars.palette.background.level3,
                },
                '&:active': {
                  '--Switch-thumbWidth': '33px',
                },
                [`&.${switchClasses.checked}`]: {
                  '--Switch-trackBackground': '#5af26b',
                  '&:hover': {
                    '--Switch-trackBackground': '#5af26b',
                  },
                },
              })}
            />
              </Box>


              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', gap:5 }} >

              <Typography sx={{ fontFamily:"montserrat", fontWeight:500, gap:{lg:"2rem", sm:"1.5rem", xs:"0.8rem" }, flexDirection:{ lg:"row", md:"row", sm:"column", xs:"column" }, backgroundColor:"#d1d1d1", padding:1.50, borderRadius:'5rem 5rem 5rem 5rem', width:{lg:"10rem", sm:"9rem", md:"10rem", xs:"8rem" }, textAlign:'center' }} > Contactless </Typography>

              <Switch
              className="switch"
              checked={contactlessCheck === 1}
              onChange={handleContLessCheck}
              disabled={ enableTxnData?.Contactless?.applicable === 0 }

              sx={(theme) => ({
                '--Switch-thumbShadow': '0 3px 7px 0 #a4bbfc',
                '--Switch-thumbSize': '25px',
                '--Switch-trackWidth': '61px',
                '--Switch-trackHeight': '30px',
                '--Switch-trackBackground': theme.vars.palette.background.level3,
                [`& .${switchClasses.thumb}`]: {
                  transition: 'width 0.2s, left 0.2s',
                },
                '&:hover': {
                  '--Switch-trackBackground': theme.vars.palette.background.level3,
                },
                '&:active': {
                  '--Switch-thumbWidth': '33px',
                },
                [`&.${switchClasses.checked}`]: {
                  '--Switch-trackBackground': '#5af26b',
                  '&:hover': {
                    '--Switch-trackBackground': '#5af26b',
                  },
                },
              })}
            />

              </Box>

               <Button disabled={  limitCardProgress } onClick={() => setTransactionEnable()} variant="contained" fullWidth sx={{ fontFamily:"montserrat", fontWeight:500 }} > { limitCardProgress ? "Loading" : "Submit Data" } </Button>
              
              </DialogContent>
            </Dialog>

                



            <Dialog
              open={openTransTable}
              onClose={handleTransTableClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{
               
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "90%", 
                  },
                },
              }}
            >
              <CloseIcon onClick={() => setOpenTransTable(false) } sx={{ backgroundColor:'red', color:'white', position:'absolute', right:0 ,fontSize:'1.2rem', cursor:'pointer' }} />
                <Tooltip title="Download Transaction's" placement='top' >
              <Button variant='contained' endIcon={ <DownloadingIcon size="1.2rem" /> } onClick={() => setDownloadTnx(true)} sx={{ position:'absolute', bottom:0, right:0, margin:1, cursor:"pointer", fontFamily:'montserrat', fontWeight:400 }} >Download Txn</Button>
                </Tooltip>
                <Typography sx={{ fontFamily:'Poppins', fontWeight:500, textTransform:'capitalize', textAlign:'center', fontSize:{lg:"1.2rem", xs:"0.6rem"}, marginTop:"1rem" }} > <span style={{ color:'#4d97ff' }} > {name}'s </span> card transaction data </Typography>
              <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column', gap:2, width:"96%" }} >
              {
                <TableContainer elevation={16} component={Paper} sx={{ marginTop:0, marginBottom:5 }} >
                    <Table sx={{ minWidth: 800 ,backgroundColor:'white' }} aria-label="simple table">
                        <TableHead>
                        <TableRow sx={{ backgroundColor:'#219bff' }} >

                        {headers.map((header, index) => (
                          <TableCell align='center' sx={{ color:"white", fontFamily:"montserrat", textTransform:"capitalize", fontSize:"0.7rem" }} key={index}>{header}</TableCell>
                        ))}
                         </TableRow>
                        </TableHead>
                        <TableBody>
                       { Array.isArray(transSlicedData) && transSlicedData?.map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {headers.map((header, headerIndex) => (
                            <TableCell sx={{ fontFamily:"Poppins", color:"black", fontWeight:400, fontSize:"0.8rem" }} key={headerIndex}>{row[header]}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                        </TableBody>
                    </Table>
                </TableContainer>
        }
              
              </DialogContent>
            </Dialog>


            <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openCatMenu}
            onClose={handleCloseCatMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
              defaultValue="Eezib Transcorp"
          >
            <MenuItem onClick={(e) => handleCatClick("All Cards")} value="eezib" sx={{ fontFamily:'montserrat', fontWeight:500 }} >Eezib All Cards</MenuItem>
            
            <MenuItem onClick={(e) => handleCatClick("Eezib Transcorp")} value={4}  sx={{ fontFamily:'montserrat', fontWeight:500 }} >Eezib Transcop</MenuItem>
            {/* <Divider variant='middle' sx={{ padding:0, marrgin:0 }} /> */}
            <MenuItem onClick={(e) => handleCatClick("Eezib Fino Payments Bank")} value={3} sx={{ fontFamily:'montserrat', fontWeight:500 }} >Eezib Fino PB</MenuItem>

          </Menu>

          <Dialog
          open={openConcent}
          // onClose={handleConcentClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CloseIcon onClick={() => handleConcentClose() } sx={{ position:"absolute", right:0, color:"white", cursor:"pointer", backgroundColor:"#ff231f" }} />
        <DialogTitle id="alert-dialog-title">
          <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:{lg:"1.2rem", sm:"1.2rem", md:"1.2rem", xs:"0.9rem" } , textAlign:'center'}}  >Consent Form</Typography>
        </DialogTitle>
        <Divider variant='middle' />
        <DialogContent>
          {
            concentData?.map((row) => (
              <Box>

                <Box>  

                <Box sx={{ display:"flex" }} >
                  
                <Checkbox sx={{ float:'right' }} onChange={handleCheck} color='primary' checked={check} inputProps={{ 'aria-label': 'controlled' }} />
                <Typography sx={{ fontFamily:"Poppins", fontWeight:400, fontSize:{lg:"1rem", sm:"1rem", md:"1rem", xs:"0.8rem" }, color:"#525252" }} >{row.consent}</Typography>
                </Box>
                {
                  row.concent2 ? 
                  <Typography sx={{ fontFamiy:"montserrat", fontWeight:500, fontSize:{lg:"1rem", sm:"1rem", md:"1rem", xs:"0.8rem" } }} >{row.consent2}  </Typography> : null
                }
                </Box>

                <Box>
                  {
                    row.consentLink !== null ? <Typography onClick={() => window.open(row.consentLink, "")} sx={{ fontFamily:"Poppins", fontWeight:400, marginTop:"0.5rem", textAlign:'center', mt:2 }} > check out <span style={{ fontFamily:"Poppins", fontWeight:500, textTransform:"capitalize", color:"#43007d", cursor:"pointer" }}>terms & conditions</span> here  </Typography> : null   
                  }
                </Box>

                  <Button fullWidth disabled={ !check } onClick={() => handleOtpDialog() }  variant='contained' sx={{ marginTop:1, fontFamily:"montserrat", fontWeight:400, mt:2 }} > continue  </Button>
             
              </Box>
            ))
          } 
        </DialogContent>
        
      </Dialog>


    
    
      <Dialog
          open={verifyDataDialog}
          onClose={verifyOtpClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "60rem",
              },
            },
          }}
        >
          <CloseIcon onClick={() => setVerifyDataDialog() } sx={{ position:"absolute", right:0, color:"white", cursor:"pointer", backgroundColor:"#ff231f", fontSize:"1.3rem" }} />
        <DialogTitle id="alert-dialog-title">
          {/* <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:"1rem" , textAlign:'center', textTransform:"capitalize", marginTop:"0.5rem", fontSize:"1.2rem" }}  > verify costumer Details </Typography> */}
          <Box sx={{ display:"flex", alignItems:'center', justifyContent:'center' }} >
          <Box component='img' src={eezibLogo} sx={{ width:{lg:"7rem", sm:"7rem", md:"7rem", xs:"4rem" } }}  />
          </Box>
        </DialogTitle>
        <Divider variant='middle' />
        <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column" }} >

            <form onSubmit={verifyuserData} >

              <Typography sx={{ fontFamily:"Poppins", fontWeight:600, fontSize:{lg:"1.5rem", sm:"1.5rem", md:"1.5rem", xs:"1rem" }, textAlign:'center', textTransform:"capitalize", color:"#2162cc", marginBottom:"1.2rem", marginTop: isXs ? 5 : 0 }} >verify customer details</Typography>

              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap: isXs ? 2 : 4, flexDirection:{ lg:"row", sm:"row", md:"row", xs:"column" } }} >

              <TextField required value={ verifyUser } onChange={(e)  => setVerifyUser(e.target.value)} variant='outlined' fullWidth focused InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} sx={{ textTransform:"capitalize", fontSize:"0.7rem" }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500, textTransform:'capitalize' ,fontSize: isXs ? "0.8rem" : "1rem" } }}  label="Customer Name" />

              <TextField value={ verifyEmail } variant='filled' fullWidth focused InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 , cursor:'not-allowed', pointerEvents:"none", fontSize: isXs ? "0.8rem" : "0.9rem" , fontWeight:500 }, readOnly: true }} label="Email" />

              <TextField value={ verifyDob } variant='filled' fullWidth focused InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 , cursor:'not-allowed', pointerEvents:"none", fontSize: isXs ? "0.8rem" : "1rem"  } }} label="DOB" />

              </Box>

              <Box >
              <Divider variant="middle" sx={{ marginTop:"1rem", fontSize:"0.7rem", color:'#4282eb', textAlign:'center', fontWeight:400 }}> Enter Address As Per Aadhar* </Divider>
              {/* <label style={{ fontSize:"0.7rem", color:'gray', textAlign:'center' }} >Enter Address As Per Aadhar*</label> */}
              </Box>

              <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap: isXs ? 1 : 4, flexDirection:{ lg:"row", sm:"row", md:"row", xs:"column" } }} >
              <TextField required value={ verifyAddress } onChange={(e) => setVerifyAddress(e.target.value)} InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500, fontSize:"0.85rem" } }} fullWidth sx={{ marginTop:3  }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 }, inputProps:{ maxLength:40 } }} label="Address 1" />
              <TextField value={ verifyAddress2 } onChange={(e) => setVerifyAddress2(e.target.value)} InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500, fontSize:"0.9rem" } }} fullWidth sx={{ marginTop:3 }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 }, inputProps:{ maxLength:40 } }} label="Address 2 (Optional) " />
              <TextField value={ verifyAddress3 } onChange={(e) => setVerifyAddress3(e.target.value)} InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500, fontSize:"0.9rem" } }} fullWidth sx={{ marginTop:3 }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 }, inputProps:{ maxLength:40 } }} label="Address 3 (Optional) " />

            </Box>

            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center', gap: isXs ? 2 : 4, flexDirection:{ lg:"row", sm:"row", md:"row", xs:"column" }, mt:3 }} >
           
            <TextField required value={ city } onChange={(e) => setCity(e.target.value)} variant='outlined' fullWidth InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 , fontSize: isXs ? "0.8rem" : "1rem"  } }} label="City" />
            <TextField required value={ state } onChange={(e) => setState(e.target.value)} variant='outlined' fullWidth InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 , fontSize: isXs ? "0.8rem" : "1rem"  } }} label="State" />
            <TextField required value={ pincode } onChange={handlePinCode} variant='outlined' fullWidth InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 , fontSize: isXs ? "0.8rem" : "1rem"  }  }} label="Pincode" />
            </Box>


              <Button disabled={verifyProg} type='submit' variant="contained" fullWidth sx={{ fontFamily:"montserrat", fontWeight:500, marginTop:3 }} >{ verifyProg ? "Loading.." : "Verify" }</Button>

            </form>

          
        </DialogContent>
        
      </Dialog>





      <Dialog
          open={ otpVerify }  
          onClose={verifyDataClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <CloseIcon onClick={() => setotpVerify(false) } sx={{ position:"absolute", right:0, color:"white", cursor:"pointer", backgroundColor:"#ff231f" }} />
        <DialogTitle id="alert-dialog-title">
          <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:isXs ? "0.8rem": "1rem" , textAlign:'center', textTransform:"capitalize", marginTop:"0.5rem" }}  >enter otp send to your registered mobile number</Typography>
        </DialogTitle>
        <Divider variant='middle' />
        <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column" }} >


              <Box component="img" src={imageLogo} sx={{ width: isXs ? "16rem" : "25rem" }} />

              <TextField type="number"  InputLabelProps={{ style:{ fontFamily:"Poppins", fontWeight:400 } }} onChange={(e) => setOtpValue(e.target.value)} value={otpValue} label="Enter One Time Password"  sx={{ width: isXs ? "16rem" : "23rem", mt:2,  "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": { WebkitAppearance: "none",margin: 0,
  },
  "input[type=number]": {
    MozAppearance: "textfield",
  } }}  />

              <Button onClick={() => handleOtpVerify()}  disabled={ otpValue?.length < 4 || otpProg  } variant='contained' sx={{ mt:2, width: isXs ? "16rem" : "23rem",  fontFamily:"Poppins", fontWeight:500 }} >{ otpProg ? "Loading.." : "Submit" }</Button>
          
        </DialogContent>
        
      </Dialog>


      <Dialog
          open={ openKYC }  
          onClose={verifyDataClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "700px",  // Set your width here
              },
            },
          }}
        >
          <CloseIcon onClick={() => handleOpenKyc()  } sx={{ position:"absolute", right:0, color:"white", cursor:"pointer", backgroundColor:"#ff231f" }} />
        <DialogTitle id="alert-dialog-title">
          <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:"1rem" , textAlign:'center', textTransform:"capitalize", marginTop:"0.5rem" }}  > KYC Verification </Typography>
        </DialogTitle>
        <Divider variant='middle' />
        <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column" }} >

          <Box>

            <Box sx={{ display:"flex", alignItems:'center', justifyContent:'center',  flexDirection:{lg:"row", sm:"row", md:"row", xs:"column" }, gap:{lg:5, sm:5, md:5, xs:2 }  }} >

            <TextField type="number" error={aadharNo?.length > 12} helperText={ aadharNo?.length > 12 ? "enter valid aadhar number" : "" }  focused value={aadharNo} onChange={(e) => setAadharNo(e.target.value)} label="Aadhar number"  sx={{ width:"17rem", "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
             WebkitAppearance: "none",
              margin: 0,
              },
            "input[type=number]": {
               MozAppearance: "textfield",
              } }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }}  InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500}}} 
            />

            <TextField focused value={panNo} onChange={(e) => panNo(e.target.value)} label="Pan Number" sx={{ width:"17rem" }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 }}} />
            </Box>

            <Box sx={{ marginTop:2, display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column" , gap:2 }} >
            <TextField focused value={fatherName} onChange={(e) => setFatherName(e.target.value)} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} label="Father Name" fullWidth  InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500}}} />
            <TextField focused value={kycAddress} onChange={(e) => setKycAddress(e.target.value)} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} label="Permanent Address" fullWidth  InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500}}} />
              {/* <label style={{ fontSize:"0.8rem", fontWeight:300, color:"#3850c7" }} >*Select Passport Size Image upto 600KB 
                
              </label>
            <input 
          ref={fileInputRef}
          id='file_input'
          accept="image/*"
          type='file' style={{  width:'13rem' }}
          onChange={handleImageChange}
          
          />   */}

              {
              <Button disabled={openBackdrop || !aadharNo || !panNo || !fatherName || !kycAddress || aadharNo?.length !== 12 } onClick={() => handleVerification()} fullWidth variant='contained' sx={{ fontFamily:"montserrat", fontWeight:500 }} >{openBackdrop ? "Loading" : "SUBMIT"}</Button>
              }

                </Box>

          </Box>
          
        </DialogContent>
        
      </Dialog>




      <Dialog
          open={ convertPhy }  
          onClose={verifyDataClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "700px",  // Set your width here
              },
            },
          }}
        >
          <CloseIcon onClick={() => setConvertPhy(false) } sx={{ position:"absolute", right:0, color:"white", cursor:"pointer", backgroundColor:"#ff231f" }} />
        <DialogTitle id="alert-dialog-title">
          <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:"1rem" , textAlign:'center', textTransform:"capitalize", marginTop:"0.5rem" }}  > Convert To Physical Card form </Typography>
        </DialogTitle>
        <Divider variant='middle' />
        <DialogContent sx={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column" }} >

          <Box>

            <Box sx={{ display:"flex", alignItems:'center', justifyContent:'center', flexDirection:{lg:"row" , md:"row", sm:"row", xs:"column" }, gap:3 }} >

            <TextField value={add1}  onChange={(e) => setAdd1(e.target.value)} label="Address 1" focused sx={{ width:"17rem"  }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }}  InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500}}}                 
                      />

            <TextField focused value={add2}  onChange={(e) => setAdd2(e.target.value)} label="Address 2" sx={{ width:"17rem" }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 }}} />
            </Box>

            <Box sx={{ marginTop:2, display:'flex', alignItems:'center', justifyContent:'center', flexDirection:"column", gap:2 }} >
             
              <Box sx={{ display:'flex', alignItems:"center", justifyContent:'center', flexDirection:{lg:"row" , md:"row", sm:"row", xs:"column" }, gap:3 }} >
            <TextField focused value={add3}  onChange={(e) => setAdd3(e.target.value)} sx={{ width:"17rem"  }} label="Address 3" InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500 }}} />
            <TextField focused value={phyCity}  onChange={(e) => setPhyCity(e.target.value)} sx={{ width:"17rem"  }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} label="City" fullWidth  InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500}}} />

              </Box>


            <Box sx={{ display:'flex', alignItems:"center", justifyContent:'center', flexDirection:{lg:"row" , md:"row", sm:"row", xs:"column" }, gap:3 }} >
            <TextField focused value={phyState}  onChange={(e) => setPhyState(e.target.value)} sx={{ width:"17rem"  }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} label="State" fullWidth  InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500}}} />
            <TextField focused value={phyPin}  onChange={(e) => setPhyPin(e.target.value)} sx={{ width:"17rem"  }} InputProps={{ style:{ fontFamily:"montserrat", fontWeight:500 } }} label="Pin" fullWidth  InputLabelProps={{ style:{ fontFamily:"montserrat", fontWeight:500}}} />

            </Box>
        

              {
              <Button disabled={!add1 || !add2 || !add3 || !phyCity || !phyState || !phyPin || phyProgress } onClick={() => convertCard() } fullWidth variant='contained' sx={{ fontFamily:"montserrat", fontWeight:500 }} >{phyProgress ? "Loading" : "SUBMIT"}</Button>
              }
                </Box>
          </Box>
          
        </DialogContent>
        
      </Dialog>


      <Dialog
        open={downloadTnx}
        onClose={handleDTxnClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // sx={{
        //   "& .MuiDialog-container": {
        //     "& .MuiPaper-root": {
        //       width: "100%",
        //       maxWidth: "700px",  // Set your width here
        //     },
        //   },
        // }}
      >
        <CloseIcon onClick={() => handleCross() } sx={{ position:"absolute", right:0, color:"red", cursor:'pointer' }} />
        <DialogTitle sx={{ textAlign:'center', fontFamily:'montserrat', fontWeight:500, fontSize:isXs ? "0.8rem": "1rem" }} id="alert-dialog-title">
          {" Download Transaction History "}
        </DialogTitle>
        <Divider variant='middle' />
        <DialogContent sx={{ display:'flex', alignItems:"center", justifyContent:'center', flexDirection:'column' }} >

            <Box>
              <Box component="img" src={downloadfile} sx={{ width:{lg:"25rem", md:"25rem", sm:'25rem', xs:"18rem" } }} />
            </Box>

            <Box sx={{ display:'flex', alignItems:'center', justifyContent:'space-between', gap:2, flexDirection:{lg:'row', mn:'row', sm:"row", xs:"column" } }} >

            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label="Select Start Date" 
              onChange={(e) => setStartDate(moment(e?.$d).format("YYYY-MM-DD"))}
              format="YYYY-MM-DD"
              disableFuture={true}
                sx={{
                '& .MuiInputBase-root': {
                  fontFamily: 'montserrat', fontWeight:500,
                },
                '& .MuiFormLabel-root': {
                  fontFamily: 'montserrat', fontWeight:500,
                },
                '& .MuiInputBase-input': {
                  fontFamily: 'montserrat', fontWeight:500,
                },
              }}  />
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
          <DatePicker label="Select End Date" 
          onChange={(e) => setEndDate(moment(e?.$d).format("YYYY-MM-DD"))}
          format="YYYY-MM-DD"
          disableFuture={true}
            sx={{
              '& .MuiInputBase-root': {
                fontFamily: 'montserrat', fontWeight:500,
              },
              '& .MuiFormLabel-root': {
                fontFamily: 'montserrat', fontWeight:500,
              },
              '& .MuiInputBase-input': {
                fontFamily: 'montserrat', fontWeight:500,
              },
            }}  />
      </DemoContainer>
    </LocalizationProvider>

            </Box>
            <Button  disabled={txnProg || !startDate || !endDate } fullWidth onClick={() => downloadTransaction() } sx={{ fontFamily:'montserrat', mt:2, fontWeight:500 }} variant="contained" > { txnProg ? "fetching Data" : "Fetch Transactions" }</Button>
            <Box sx={{ marginTop:2, width:"100%" }} >
            {
              reportData?.length >= 1 ? <Box  sx={{ display:'flex', alignItems:'center', justifyContent:'space-evenly' }} >
                <Box onClick={() => generateExcel() } sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' , flexDirection:'column', cursor:"pointer" }} >
                <Box component='img' src={exceLogo} />
                  <Typography sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:isXs ? "0.75rem" : "1rem" }} >Download Excel</Typography>
                </Box>

                <Box onClick={() => generatePdf() } sx={{ display:'flex', alignItems:'center', justifyContent:'space-between' , flexDirection:'column', cursor:"pointer" }} >
                <Box component='img' src={pdfLogo} />
                  <Typography sx={{ fontFamily:'montserrat', fontWeight:500, fontSize:isXs ? "0.75rem" : "1rem" }} >Download PDF</Typography>
                </Box>
                </Box>
               : null
            }
            </Box>
        </DialogContent>
   
      </Dialog>






      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        // onClick={handleBackdropClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

     


      <Snackbar
          anchorOrigin={{ vertical:"top", horizontal:"right" }}
          open={snackRightOpen}
          onClose={handleRightSnackClose}
          autoHideDuration={3000}
        >
             <Alert onClose={handleRightSnackClose} severity='success' variant='filled' sx={{ fontFamily:"montserrat", fontWeight:400 }}>{snackMessage}</Alert> 
            </Snackbar>  
            

            <Snackbar
                anchorOrigin={{ vertical:"top", horizontal:"right" }}
                open={snackRightEOpen}
                onClose={handleRightSnackEClose}
                autoHideDuration={3000}
              >
             <Alert onClose={handleRightSnackEClose} severity='error' variant='filled' sx={{ fontFamily:"montserrat", fontWeight:400 }}>{snackEError}</Alert> 
            </Snackbar>

            <Menu
              id="basic-menu"
              anchorEl={anchorEmenu}
              open={ openMenu }
              onClose={handleMenuClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              
              {
              Array.isArray(currentMenuData) && currentMenuData?.map((data, index) => {          
                return (
                <>
                  <Tooltip key={data.Action} title={data.Action} >
                  <Box component='img' onClick={() => decideRole( data.Action , currentMenuId , data.Api , data.PType ) } src={`${process.env.REACT_APP_UAPI_URL}${data.Img}`  } sx={{ width:"1.4rem", marginLeft:1, cursor:'pointer' }} />
                  </Tooltip>
                </>
                )
                } )
            }
             
            </Menu>


            <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openVoucher}
            onClose={handleVoucherClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClose}>Eezib Gift Voucher</MenuItem>
            
          </Menu>


    </Box>
    )
}

export default ActivatedCard