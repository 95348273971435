import logo from './logo.svg';
import './App.css';
import { Box, Typography } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import SelectCard from './pages/SelectCard';
import ViewCard from './pages/ViewCard';
import ActivatedCard from './pages/ActivatedCard';
import ActivatingCard from './pages/ActivatingCard';
import TransFetchDetails from './pages/TransFetchDetails';
import ValidateUser from './pages/ValidateUser';

function App() {
  return (
    <BrowserRouter basename='/ui' >
      <Routes>
        <Route path='/' element={ <Login /> } />
        <Route path="/validateUser" element={ <ValidateUser/> } />
        <Route path="/viewCard" element={ <ViewCard/> } />
        <Route path="/showActivatedCards" element={ <ActivatedCard/> } />
        <Route path="/activatingCard" element={ <ActivatingCard/> } />
        <Route path="/transFetchDetails" element={ <TransFetchDetails/> } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
