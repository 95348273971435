import { Box, CircularProgress, Typography } from '@mui/material'
import Cookies from 'js-cookie'
import React from 'react'
import { useNavigate } from 'react-router-dom';
import env from 'react-dotenv';
import { HandleApi } from '../HandleApi';

const ValidateUser = () => {

    const navigate = useNavigate();

    const accessToken = Cookies.get("demoCAuth");
    console.log(accessToken);

    const removeCookies = () => {
        Cookies.remove("demoCAuth");
        Cookies.remove("userName");
        Cookies.remove("tcValue");
        Cookies.remove("demoAuth");

      }

    const validateUser = async() => {
        try{
            // const fetchData = await fetch(`${url}/api/validate_login`, {
                //     method:"POST",
                //     headers:{
                    //         "Content-Type":"application/json",
                    //         "Authorization":`Bearer ${accessToken}`
                    //     },
                    //     body:JSON.stringify({})
                    // });
                    
                    // const json = await fetchData.json();
                    
            const url = process.env.REACT_APP_UAPI_URL;
            const api = `${url}/api/validate_login` ;
            const method = "POST";
            const bodyData = "";
            
          const json = await HandleApi({"apiUrl":api, "method":method, "bodyData":bodyData , "authAccess":accessToken });

            console.log("validate data : ",json);   
    
            if(json){
                if(json.Status === "Success"){
                    console.log("entered success validate")
                    const newAuth = json.Message.access_token;
                    const userName = json.Message.UserData.name
                    console.log("new auth : ",newAuth , " name : ",userName);
                    Cookies.set("demoCAuth",newAuth);
                    Cookies.set("userName",userName);
                    Cookies.set("darkMode",false);
                    Cookies.set("tcValue","eezib")
                    navigate('/showActivatedCards');
                }

                if(json.Status === "Error"){
                    Cookies.remove("demoCAuth")
                   // window.location.replace( `${process.env.REACT_APP_UAPI_URL}/logout` )
                }

                if(json.message === "Unauthenticated."){
                    removeCookies();
                    // window.location.replace( `${process.env.REACT_APP_UAPI_URL}/logout` );
                  }
            }
        }catch(err){
            console.log(err);
            removeCookies();
                    // window.location.replace( `${process.env.REACT_APP_UAPI_URL}/logout` );
        }

    }

    React.useEffect(() => {
        validateUser()
    },[])

  return (
    <Box sx={{ display:'flex', alignItems:'center', justifyContent:'center' }} >
        <Box sx={{ marginTop:10, display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }} >
            <CircularProgress size={180} thickness={1.5 } />
            <Typography sx={{ fontFamily:'montserrat', fontWeight:600, fontSize:"1.5rem", marginTop:5 , backgroundColor:'#2d58ad', padding:'0.6rem 1.2rem', borderRadius:"0.5rem", color:'white' }} >validating user</Typography>
        </Box>
    </Box>
  )
}

export default ValidateUser